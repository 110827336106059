<template>
<div class="container">
  <div class="row" v-if="mode === 'update'">
    <display-field label="Channel Code" group-class="col-auto">{{channelCode}}</display-field>
  </div>
  
  <div class="row" v-else>
    <input-select v-model="formData.channelCode" label="Channel Code" group-class="col-auto mb-2">
      <option v-for="ch in channelCodes" :key="ch" :value="ch">{{ch}}</option>
    </input-select>
  </div>
  
  <div v-if="defaultFeeRules && defaultFeeRules.payinEnabled" class="row border border-warning py-1 mb-1">
    <div class="col-12 fst-italic text-warning fw-bold bg-dark my-0 py-1 mb-2">Pay-In Transaction Fee</div>
    <display-field label="Default Transaction Fee" group-class="col-12 text-dark fst-italic">
      <span v-if="defaultFeeRules && defaultFeeRules.payinFeeRule">{{defaultFeeRules.payinFeeRule.feeDescription}}</span>
      <span v-else>Zero Fees</span>
    </display-field>
    <div class="form-check form-switch col-12 ms-3">
      <input v-model="payinFeeOverride" class="form-check-input" type="checkbox" id="payinFeeOverride">
      <label class="form-check-label" for="payinFeeOverride">
        Override Fee? ... <span class="text-danger fst-italic small fw-bold">{{payinFeeOverride ? 'Yes, use override below' : 'No, use default above'}}</span>
      </label>
    </div>
    <div v-if="payinFeeOverride" class="col-auto ms-3 mb-2">
      <div class="row">
      <input-number v-model="payinFeeRule.flatFeeAmount" label="Txn Fee - Flat Amount" :append="defaultFeeRules.currency" :errors="errors" error-key="payinFeeRule.flatFeeAmount" group-class="col-auto"/>
      <input-number v-model="payinFeeRule.pctOfTxnAmount" label="Txn Fee - % of Txn Amount" append="%" :errors="errors" error-key="payinFeeRule.pctOfTxnAmount" group-class="col-auto"/>
      </div>
    </div>
  </div>
  <div v-else class="row border border-warning py-2 mb-1">
    <div class="col-12 fst-italic text-warning fw-bold bg-dark my-0 py-1 mb-2">Pay-In - <span class="text-danger">Disabled</span></div>
  </div>
  
  <div v-if="defaultFeeRules && defaultFeeRules.payoutEnabled" class="row border border-warning py-1 mb-1">
    <div class="col-12 fst-italic text-warning fw-bold bg-dark my-0 py-1 mb-2">Pay-Out Transaction Fee</div>
    <display-field label="Default Transaction Fee" group-class="col-6 text-dark fst-italic">
      <span v-if="defaultFeeRules && defaultFeeRules.payoutFeeRule">{{defaultFeeRules.payoutFeeRule.feeDescription}}</span>
      <span v-else>Zero Fees</span>
    </display-field>
    <div class="form-check form-switch col-12 ms-3">
      <input v-model="payoutFeeOverride" class="form-check-input" type="checkbox" id="payoutFeeOverride">
      <label class="form-check-label" for="payoutFeeOverride">
        Override Fee? ... <span class="text-danger fst-italic small fw-bold">{{payoutFeeOverride ? 'Yes, use override below' : 'No, use default above'}}</span>
      </label>
    </div>
    <div v-if="payoutFeeOverride" class="col-auto ms-3 mb-2">
      <div class="row">
      <input-number v-model="payoutFeeRule.flatFeeAmount" label="Txn Fee - Flat Amount" :append="defaultFeeRules.currency" :errors="errors" error-key="payoutFeeRule.flatFeeAmount" group-class="col-auto"/>
      <input-number v-model="payoutFeeRule.pctOfTxnAmount" label="Txn Fee - % of Txn Amount" append="%" :errors="errors" error-key="payoutFeeRule.pctOfTxnAmount" group-class="col-auto"/>
      </div>
    </div>
  </div>
  <div v-else class="row border border-warning py-2 mb-1">
    <div class="col-12 fst-italic text-warning fw-bold bg-dark my-0 py-1 mb-2">Pay-Out - <span class="text-danger">Disabled</span></div>
  </div>

  <div class=" row mt-5 mb-1 border-top py-1">
    <div class="col-auto mx-auto text-primary fw-bold small"> Merchant-Specific Proc Attributes</div>
  </div>
  
  <div class="row">
    <div class="col-12">
    <table class="table table-sm">
      <thead class="table-dark small">
        <tr class="small">
          <th width="40%">Attribute Name</th>
          <th width="55%">Attribute Value</th>
          <th width="5%">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(attr, index) in attribs" v-bind:key="index">
          <td><input class="form-control form-control-sm" type="text" v-model="attr.name"/></td>
          <td><input class="form-control form-control-sm" type="text" v-model="attr.value"/></td>
          <td class="text-center"><a href="#"><i class="fas fa-trash-alt" v-on:click="onDelAttrib(index)"></i></a></td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
          <td class="text-center"><a href="#"><i class="fas fa-plus" v-on:click="onAddAttrib()"></i></a></td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
  
  <div v-if="pleaseWait" class="mt-5 mb-3 text-center text-primary">
    <b-spinner variant='primary' label='Please wait'></b-spinner>
    <span class="ms-3 mt-1">Processing ... Please wait</span>
  </div>
  <div v-else class="text-end mt-5 mb-3">
    <button class="btn btn-secondary me-2" @click.prevent="onCancel">Cancel</button>
    <button class="btn btn-primary" @click.self.prevent="onSave">Save</button>
  </div>
    
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'MerchantChannelForm',
  mixins: [HelpersMixin],
  props: {
    merchantId: String,
    channelCode: String,
    mode: String
  },
  data () {
    return {
      formData: {},
      attribs: [],
      channelCodes: [],
      payinFeeRule: {flatFeeAmount: 0, pctOfTxnAmount: 0},
      payoutFeeRule: {flatFeeAmount: 0, pctOfTxnAmount: 0},
      defaultFeeRules: {},
      payinFeeOverride: false,
      payoutFeeOverride: false,
      formDisabled: false,
      pleaseWait: false
    }
  },
  mounted () {
    if (this.mode === 'add') {
      this.getChannelCodes()
      this.formData.merchantId = this.merchantId
      this.$watch('formData.channelCode', this.getDefaultFeeRules)
    }
    if (this.mode === 'update') {
      this.getMxChannel()
    }
  },
  methods: {
    getMxChannel: function () {
      this.axios.get('/ax/merchantchannel/' + this.merchantId + '/' + this.channelCode)
      .then(response => {
        this.formData = response.data
        this.getDefaultFeeRules(this.formData.channelCode)
        this.propsToAttribs(this.formData.attribs)
        if (this.formData.payinFeeRule) {
            this.payinFeeOverride = true
            this.payinFeeRule = this.formData.payinFeeRule
        }
        if (this.formData.payoutFeeRule) {
            this.payoutFeeOverride = true
            this.payoutFeeRule = this.formData.payoutFeeRule
        }
      })
      .catch(error => this.handleError(error))
    },
    onAddAttrib: function () {
      this.attribs.push({ name: '', value: '' })
    },
    onDelAttrib: function (idx) {
      this.attribs.splice(idx, 1)
    },
    propsToAttribs: function (props) {
      for (var key in props) {
        this.attribs.push({ name: key, value: props[key] })
      }
    },
    attribsToMap: function () {
      var attribMap = {}
      for (var i = 0; i < this.attribs.length; i++) {
        attribMap[this.attribs[i].name] = this.attribs[i].value
      }
      this.formData.attribs = attribMap
    },
    onSave: function () {
      this.pleaseWait = true
      this.$forceUpdate()
      this.attribsToMap()
      if (this.payinFeeOverride) {
          this.formData.payinFeeRule = this.payinFeeRule
      } else {
          this.formData.payinFeeRule = null
      }
      if (this.payoutFeeOverride) {
          this.formData.payoutFeeRule = this.payoutFeeRule
      } else {
          this.formData.payoutFeeRule = null
      }
      if (this.mode === 'add') {
        this.axios.put('/ax/merchantchannel', this.formData)
        .then(() => {
          this.$emit('saved')
          this.$toasted.success('Channel ' + this.formData.channelCode + ' enabled for merchant')
        })
        .catch(error => {
          this.pleaseWait = false
          this.handleError(error)
        })
      } else {
        this.axios.post('/ax/merchantchannel', this.formData)
        .then(() => {
          this.$emit('saved')
          this.$toasted.success('Merchant Channel ' + this.channelCode + ' updated')
        })
        .catch(error => {
          this.pleaseWait = false
          this.handleError(error)
        })
      }
      
    },
    onCancel: function () {
      this.$emit('cancelled')
    },
    getChannelCodes: function () {
      this.axios.get('/ax/paymentchannelcodes')
      .then(response => {
        this.channelCodes = response.data
      })
      .catch(error => this.handleError(error))
    },
    getDefaultFeeRules: function () {
        this.axios.get("/ax/defaultfeerules/" + this.formData.channelCode)
        .then(response => {
            this.defaultFeeRules = response.data
        })
        .catch(error => {
          this.handleError(error)
        })
    }
  }
}
</script>