<template>
<div class="form-group" :class="groupClass">
  <label v-if="label">{{label}}</label>
  <input class="form-control" :type="masked ? 'password' : 'text'" ref="inputelem"
    :placeholder="placeholder"
    v-model="inputData"
    :class="[{'is-invalid': hasError()}, inputClass]"/>
  <small class="invalid-feedback" v-if="hasError()">{{errorMsg}}</small>
</div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    label: String,
    value: String,
    inputClass: String,
    groupClass: String,
    placeholder: String,
    focus: Boolean,
    masked: Boolean,
    errors: { type: Object, default: () => ({}) },
    errorKey: String
  },
  mounted () {
    if (this.focus) {
      this.$refs.inputelem.focus()
    }
  },
  computed: {
    inputData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  data () {
    return {
      errorMsg: ''
    }
  },
  methods: {
    hasError: function () {
      if (this.errors.fldErrors && this.errorKey) {
        const err = this.errors.getError(this.errorKey)
        if (err) {
          this.errorMsg = err.message
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped>
label { font-size: 75%; margin-bottom: 1px; font-weight: 600; }
::placeholder { font-size: 75%; color: #999; }
:-ms-input-placeholder { font-size: 75%; color: #999; }
::-ms-input-placeholder { font-size: 75%; color: #999; }
</style>
