<template>
<div class="container">
  <h5 class="text-center mb-3">Reference Data</h5>
  
  <div class="row mb-3">
    <div class="col col-auto">
      <input-select v-model="refType" label="Reference Data Type">
        <option v-for="r in refTypeList" v-bind:key="r">{{r}}</option>
      </input-select>
    </div>
  </div>
  
  <div class="row mb-1" v-if="refDataList">
    <a class="col-auto ms-auto small pointer" @click="showRefDataForm()"><i class="fa fa-plus-circle me-1" aria-hidden="true"></i>CREATE NEW</a>
  </div>
  
  <div class="row" v-if="refDataList">
    <table class="col col-auto table table-striped">
      <thead class="thead-dark">
        <tr>
          <th width="35%">Code</th>
          <th width="50%">Value</th>
          <th width="15%">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="rd in refDataList" v-bind:key="rd.refCode">
          <td>{{rd.refCode}}</td>
          <td>{{rd.refValue}}</td>
          <td class="text-center">
            <a @click="showRefDataForm(rd)"><i class="fas fa-edit pointer me-2 text-primary"></i></a>
            <a @click="confirmDelete(rd)"><i class="fas fa-trash pointer text-danger"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <b-modal id="refdata-form" :title="formTitle" size="lg" hide-footer no-close-on-backdrop>
    <div>
      <display-field label="Type" group-class="ms-1">{{formData.refType}}</display-field>
      <display-field label="Code" group-class="ms-1" v-if="inputMode === 'edit'">{{formData.refCode}}</display-field>
      <form>
        <fieldset :disabled="formDisabled">
          <input-text v-model="formData.refCode" label="Code" placeholder="Code" v-if="inputMode === 'add'"></input-text>
          <input-text v-model="formData.refValue" label="Value" placeholder="Value"></input-text>
        </fieldset>
      </form>
      <div v-if="formDisabled" class="mt-5 mb-3 text-center text-primary">
        <b-spinner variant='primary' label='Please wait'></b-spinner>
        <span class="ms-3 mt-1">Processing ... Please wait</span>
      </div>
      <div v-else class="text-right mt-5 mb-3">
        <button class="btn btn-secondary me-2" v-on:click="onCancel">Cancel</button>
        <button class="btn btn-primary" v-on:click.self.prevent="onSave">Save</button>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  name: 'ReferenceData',
  data () {
    return {
      refDataList: null,
      refTypeList: [],
      refType: '',
      formTitle: '',
      formData: { refType: '', refCode: '', refValue: '' },
      formDisabled: false,
      inputMode: 'edit'
    }
  },
  watch: {
    refType: function () {
      this.getRefDataList()
    }
  },
  mounted () {
    this.getRefTypes()
  },
  methods: {
    getRefTypes: function () {
      this.axios.get('/ax/refdata/reftypes')
      .then(response => {
        this.refTypeList = response.data
      })
      .catch(error => this.handleError(error))
    },
    getRefDataList: function () {
      this.refDataList = null
      this.axios.get('/ax/refdata/reftype/' + this.refType)
      .then(response => {
        this.refDataList = response.data
      })
      .catch(error => this.handleError(error))
    },
    showRefDataForm: function (refData) {
      this.formDisabled = false
      this.formData.refType = this.refType
      if (refData) {
        this.inputMode = 'edit'
        this.formTitle = 'EDIT Reference Data'
        this.formData.refCode = refData.refCode
        this.formData.refValue = refData.refValue
      } else {
        this.inputMode = 'add'
        this.formTitle = 'CREATE Reference Data'
        this.formData.refCode = ''
        this.formData.refValue = ''
      }
      this.$bvModal.show('refdata-form')
    },
    onCancel: function () {
      this.$bvModal.hide('refdata-form')
    },
    onSave: function () {
      this.formDisabled = true
      if (this.inputMode === 'add') {
        this.axios.put('/ax/refdata', this.formData)
        .then(() => {
          this.$toasted.success('Successfully created reference data ' + this.formData.refType + ' / ' + this.formData.refCode)
          this.$bvModal.hide('refdata-form')
          this.getRefDataList()
        })
        .catch(error => this.handleError(error))
      } else if (this.inputMode === 'edit') {
        this.axios.post('/ax/refdata', this.formData)
        .then(() => {
          this.$toasted.success('Successfully updated reference data ' + this.formData.refType + ' / ' + this.formData.refCode)
          this.$bvModal.hide('refdata-form')
          this.getRefDataList()
        })
        .catch(error => this.handleError(error))
      }
    },
    confirmDelete: function (rd) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete Reference Data: ' + rd.refType + ' / ' + rd.refCode, {
        title: 'Please Confirm',
        bodyClass: 'font-weight-bold text-danger text-center',
        centered: true
      })
      .then(value => {
        if (value === true) {
          this.processDelete(rd)
        }
      })
      .catch(err => {
          console.log(JSON.stringify(err))
          this.$toasted.error('Unexpected error occurred while processing deletion ... check console log')
      })
    },
    processDelete: function (rd) {
      console.log('deleting ' + rd.refType + '-' + rd.refCode)
      this.axios.delete('/ax/refdata/' + rd.refType + '/' + rd.refCode)
      .then(() => {
        this.$toasted.success('Successfully deleted reference data ' + rd.refType + ' / ' + rd.refCode)
        this.getRefDataList()
      })
      .catch(error => this.handleError(error))
    },
    handleError: function (error) {
      this.formDisabled = false
      if (error.response && error.response.data && error.response.data.msgText) {
        this.$toasted.error(error.response.data.msgText)
      } else {
        console.log(JSON.stringify(error))
        this.$toasted.error('Unexpected error ... please try again later')
      }
    }
  }
}
</script>

<style scoped>
.pointer { cursor: pointer; }
</style>
