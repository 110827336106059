<template>
<div class="container">
  <page-title title="Admin Users"/>

  <div class="row" v-if="isPermitted('AX_ADMINUSERMAINT')">
    <div class="col-auto ms-auto small">
      <a @click.prevent="showForm()" href="#"><i class="fas fa-plus-square"></i> Add User</a>
    </div>
  </div>

  <div class="row">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>User ID</th>
          <th>Name</th>
          <th>Email Address</th>
          <th>Last Update</th>
          <th>Last Login</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="axu in axuList" :key="axu.userId"  @click="showForm(axu)" style="cursor: pointer;">
          <td>{{axu.userId}}</td>
          <td>{{axu.firstName}} {{axu.lastName}}</td>
          <td>{{axu.emailAddress}}</td>
          <td><display-date :value="axu.lastUpdate"/></td>
          <td><display-date :value="axu.lastLogin"/>
            <span v-if="axu.locked" class="text-danger small fw-bold"><br/>LOCKED OUT</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <b-modal id="axu-form" title="Admin User" hide-footer>
    <admin-user-form :user-id="selectedUserId"
      @cancelled="$bvModal.hide('axu-form')"
      @saved="refreshList">
    </admin-user-form>
  </b-modal>

</div>

</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import AdminUserForm from '../components/AdminUserForm.vue'
export default {
  name: 'AdminUsers',
  mixins: [HelpersMixin],
  components: {
    AdminUserForm
  },
  data () {
    return {
      axuList: [],
      selectedUserId: ''
    }
  },
  mounted () {
    this.getAdminUsers()
  },
  methods: {
    getAdminUsers: function () {
      this.axios.get('/ax/adminusers')
      .then(response => {
        this.axuList = response.data
      })
      .catch(error => this.handleError(error))
    },
    showForm: function (axu) {
      if (axu) {
        this.selectedUserId = axu.userId
      } else {
        this.selectedUserId = ''
      }
      this.$bvModal.show('axu-form')
    },
    refreshList: function () {
      this.$bvModal.hide('axu-form')
      this.getAdminUsers()
    }
  }
}
</script>