<template>
<div class="row" :class="calcRowClass">
  <h5 class="col-auto mx-auto">{{title}}</h5>
</div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: ['title', 'rowClass'],
  data () {
    return {
      calcRowClass: ''
    }
  },
  mounted () {
    if (this.rowClass) {
      this.calcRowClass = this.rowClass
    } else {
      this.calcRowClass = 'mb-3'
    }
  }
}
</script>
