<template>
<div class="container">
  <wait-inline v-if="isLoading"/>
  <div class="row">
    <display-field group-class="col" label="Transaction Result Code/Message">
      {{procResult.txnResultCode}}
      <span v-if="procResult.txnResultMessage"> - {{procResult.txnResultMessage}}</span>
    </display-field>
  </div>
  <div class="row">
    <display-field group-class="col" label="Raw Processor Result">
      <span v-if="procResult.rawResponse">{{procResult.rawResponse}}</span>
      <span v-else class="text-warning">** Raw Result Not Available **</span>
    </display-field>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'ProcResult',
  mixins: [HelpersMixin],
  props: [ 'txnId' ],
  data () {
    return {
      procResult: {}
    }
  },
  mounted () {
    this.isLoading = true
    this.getProcResult()
  },
  methods: {
    getProcResult: function () {
      this.isLoading = true
      this.axios.get('/ax/txn/procresult/' + this.txnId)
      .then(result => {
        this.procResult = result.data
        this.isLoading = false
      })
      .catch(error => this.handleError(error))
    }
  }
}
</script>