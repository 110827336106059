import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: JSON.parse(sessionStorage.getItem('axtoken') || '{}'),
    mxList: null,
    mxLookup: {}
  },
  mutations: {
    saveToken (state, t) {
      sessionStorage.setItem('axtoken', JSON.stringify(t))
      state.token = t
    },
    clearToken (state) {
      sessionStorage.removeItem('axtoken')
      state.token = {}
    },
    saveSearchParams (state, p) {
      state.searchParams = p
    },
    clearSearchParams (state) {
      state.searchParams = null
    },
    saveMxList (state, m) {
      state.mxList = m
    },
    clearMxList (state) {
      state.mxList = null
    },
    saveMxLookup (state, m) {
      state.mxLookup = m
    },
    clearMxLookup (state) {
      state.mxLookup = null
    }
  },
  actions: {

  }
})
