<template>
<div class="container">
  <page-title title="Dashboard" row-class="mb-0"/>
  <div class="row small mt-0 pt-0 mb-3 text-secondary">
    <div class="col col-auto mx-auto">
      <span v-if="timeAgo">As of: {{ timeAgo }}</span>
      <span class="ps-2"><a href="#" @click="refresh()" v-b-tooltip.hover title="Refresh"><i class="fas fa-sync fa-lg"></i></a></span>
    </div>
  </div>
  
  <div class="row mb-5">
    <div class="col-auto me-auto">
      <period-selector @periodUpdated="onPeriodUpdated" default-period="TODAY" />
    </div>
    <div class="col-auto">
      <merchant-select @merchantSelected="onMerchantSelected"/>
    </div>
  </div>
  
  
  <div class="row table-responsive">
    <table class="col-auto table table-bordered small">
      <thead class="table-dark">
        <tr>
          <th rowspan="2" class="align-middle">Channel</th>
          <th colspan="2" class="text-center">Failed/Cancelled</th>
          <th colspan="2" class="text-center">Pending/In Process</th>
          <th colspan="3" class="text-center">Completed Pay-Ins</th>
          <th colspan="2" class="text-center">Completed Pay-Outs</th>
        </tr>
        <tr>
          <th width="7%" class="text-center small bg-secondary">Count</th>
          <th width="10%" class="text-center small bg-secondary">Amount</th>
          <th width="7%" class="text-center small bg-secondary">Count</th>
          <th width="10%" class="text-center small bg-secondary">Amount</th>
          <th width="7%" class="text-center small bg-secondary">Count</th>
          <th width="12%" class="text-center small bg-secondary">Requested</th>
          <th width="12%" class="text-center small bg-secondary">Processed</th>
          <th width="7%" class="text-center small bg-secondary">Count</th>
          <th width="12%" class="text-center small bg-secondary">Processed</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="summary.channelSummList && summary.channelSummList.length > 0">
        <tr v-for="csumm in summary.channelSummList" :key="csumm.channelCode">
          <td>{{csumm.channelCode}}</td>
          <td class="text-center small">{{csumm.totalFailedCancelled.count}}</td>
          <td class="text-end small"><display-amount :value="csumm.totalFailedCancelled.requestedAmount" :currency="csumm.currency"/></td>
          <td class="text-center small">{{csumm.totalPendingInProcess.count}}</td>
          <td class="text-end small"><display-amount :value="csumm.totalPendingInProcess.requestedAmount" :currency="csumm.currency"/></td>
          <td class="text-center payin-col">{{csumm.completedPayins.count}}</td>
          <td class="text-end payin-col"><display-amount :value="csumm.completedPayins.requestedAmount" :currency="csumm.currency"/></td>
          <td class="text-end payin-col"><display-amount :value="csumm.completedPayins.processedAmount" :currency="csumm.currency"/></td>
          <td class="text-center payout-col">{{csumm.completedPayouts.count}}</td>
          <td class="text-end payout-col"><display-amount :value="csumm.completedPayouts.processedAmount" :currency="csumm.currency"/></td>
        </tr>
        </template>
        <template v-else>
        <tr>
          <td colspan="10" class="text-center fw-bold text-warning py-3">No transactions for the period</td>
        </tr>
        </template>
      </tbody>
    </table>
  </div>
  
  <wait-modal :loading="isLoading"/>
</div>
</template>

<script>
import moment from 'moment'
import WaitModal from '../components/WaitModal.vue'
import PeriodSelector from '../components/PeriodSelector.vue'
import MerchantSelect from '../components/MerchantSelect.vue'
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'DashboardView',
  mixins: [HelpersMixin],
  components: { WaitModal, PeriodSelector, MerchantSelect },
  data () {
    return {
      initDone: false,
      startDate: '',
      endDate: '',
      merchantId: 'ALL',
      accountId: 'ALL',
      summary: {},
      poller: null,
      refreshTimer: null,
      lastRefresh: new Date(),
      timeAgo: null
    }
  },
  mounted () {
    this.poller = setInterval(this.refresh, 300000)
    this.refreshTimer = setInterval(this.setTimeAgo, 60000)
    this.$nextTick(function () {
        this.initDone = true
        this.getSummary()
    })
  },
  beforeDestroy () {
    if (this.poller) {
      clearInterval(this.poller)
    }
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  },
  methods: {
    getSummary: function () {
      this.isLoading = true
      if (this.merchantId === 'ALL') {
        this.merchantId = ''
      }
      var params = {startDate: this.startDate, endDate: this.endDate, merchantId: this.merchantId}
      if (this.accountId !== 'ALL') {
        params.accountId = this.accountId
      }
      this.axios.post('/ax/summarybychannel', params)
      .then(response => {
        this.summary = response.data
        this.isLoading = false
        this.setTimeAgo()
      })
      .catch(error => this.handleError(error))
    },
    onMerchantSelected: function (eventData) {
      this.merchantId = eventData.merchantId
      this.accountId = eventData.accountId
      if (this.initDone) {
          this.getSummary()
      }
    },
    onPeriodUpdated: function (eventData) {
      this.startDate = eventData.startDate
      this.endDate = eventData.endDate
      if (this.initDone) {
          this.getSummary()
      }
    },
    setTimeAgo: function () {
      this.timeAgo = moment(this.lastRefresh).fromNow()
    },
    refresh: function () {
      this.getSummary()
      this.lastRefresh = new Date()
      this.setTimeAgo()
    }
  }
}
</script>

<style scoped>
.payin-col { background-color: #ccffcc; font-weight: bold; }
.payout-col { background-color: #ffe6e6; font-weight: bold; }
</style>
