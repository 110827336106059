import { ErrorsContainer } from '../utils/ErrorsContainer.js'
export default {
  data () {
    return {
      errors: new ErrorsContainer(),
      isLoading: false
    }
  },
  methods: {
    handleError: function (error) {
      this.isLoading = false
      if (error.response.data && error.response.data.msgCode) {
        const errMsg = error.response.data
        this.$toasted.error(errMsg.msgText)
      } else {
        console.log(JSON.stringify(error))
        this.$toasted.error('Unexpected system error ... please try again later')
      }
    },
    handleValidationErrors: function (error) {
      this.isLoading = false
      this.errors.clearAll()
      if (error.response.data && error.response.data.msgCode) {
        const errMsg = error.response.data
        if (errMsg.msgCode === 'E9998') {
          this.errors.setFldErrors(errMsg.fieldErrors)
        } else {
          this.$toasted.error(errMsg.msgText)
        }
      } else {
        console.log(JSON.stringify(error))
        this.$toasted.error('Unexpected system error ... please try again later')
      }
    },
    isPermitted: function (fxn) {
      if (this.$store.state.token.permstr && this.$store.state.token.permstr.includes(fxn)) {
        return true
      }
      return false
    },
    backToHome: function () {
      this.$router.push('/')
    }
  }
}