<template>
<div class="container">
  <div class="row" v-if="isPermitted('AX_MERCHANTMAINT')">
    <div class="col-auto ms-auto small">
      <a @click.prevent="showCreateForm()" href="#"><i class="fas fa-plus-square"></i> Add Account</a>
    </div>
  </div>
  
  <div class="row">
    <table class="table small table-hover" v-if="mxAccounts.length > 0">
      <thead class="thead-dark">
        <tr>
          <th>Account ID</th>
          <th>Account Name</th>
          <th>Last Updated</th>
          <th>Last Update By</th>
          <th>Has Fee Overrides</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="mxa in mxAccounts" :key="mxa.accountId" @click="showDetails(mxa)" style="cursor: pointer;">
          <td>{{mxa.accountId}}</td>
          <td>{{mxa.accountName}}</td>
          <td><display-date :value="mxa.lastUpdate"></display-date></td>
          <td>{{mxa.lastUpdateBy}}</td>
          <td>{{hasFeeOverrides(mxa) ? 'YES' : 'NO'}}</td>
          <td>{{mxa.active ? 'ACTIVE' : 'INACTIVE'}}</td>
        </tr>
      </tbody>
    </table>
    <div v-else class="text-center text-warning">No Merchant Accounts Found</div>
  </div>

  <b-modal id="mxa-details" title="Merchant Account" hide-footer size="xl">
    <merchant-account-details :merchant-id="merchantId" :account-id="selectedAcct.accountId"
      @cancelled="$bvModal.hide('mxa-details')"
      @saved="accountUpdated">
    </merchant-account-details>
  </b-modal>
  
  <b-modal id="mxa-create" title="Create Merchant Account" hide-footer>
    <div class="container">
      <div class="row">
        <div class="col text-warning fst-italic fw-bold small">Enter Account Name</div>
      </div>
      <div class="row">
        <input-text v-model="accountNameInput" group-class="col"></input-text>
        <div class="col-auto">
        <a href="#" class="text-danger fs-5 me-2" @click.prevent="$bvModal.hide('mxa-create')"><i class="fa-solid fa-xmark"></i></a>
        <a href="#" class="text-success fs-5" @click.prevent="processCreateAccount()"><i class="fa-solid fa-check"></i></a>
        </div>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import MerchantAccountDetails from '../components/MerchantAccountDetails.vue'
export default {
  name: 'MerchantAccounts',
  mixins: [HelpersMixin],
  components: {MerchantAccountDetails},
  props: ['merchantId'],
  data () {
    return {
      mxAccounts: [],
      selectedAcct: {},
      accountNameInput: ''
    }
  },
  mounted () {
    this.getMerchantAccounts()
  },
  methods: {
    getMerchantAccounts: function () {
      this.axios.get('/ax/mxaccounts/' + this.merchantId)
        .then(response => {
          this.mxAccounts = response.data
        })
        .catch(error => this.handleError(error))
    },
    showDetails: function (mxa) {
      this.selectedAcct = mxa
      this.$bvModal.show('mxa-details')
    },
    accountUpdated: function () {
      this.getMerchantAccounts()
      this.$bvModal.hide('mxa-details')
    },
    showCreateForm: function () {
        this.accountNameInput = ''
        this.$bvModal.show('mxa-create')
    },
    processCreateAccount: function () {
        const payload = {merchantId: this.merchantId, accountName: this.accountNameInput}
        this.axios.put('/ax/mxaccount', payload)
        .then( () => {
            this.$bvModal.hide('mxa-create')
            this.getMerchantAccounts()
        })
        .catch(error => this.handleError(error))
    },
    hasFeeOverrides: function (mxa) {
        if (mxa == null) {
            return false
        }
        if (mxa.payinFeeRules && Object.values(mxa.payinFeeRules).length > 0) {
            return true
        }
        if (mxa.payoutFeeRules && Object.values(mxa.payoutFeeRules).length > 0) {
            return true
        }
        return false
    }
  }
}
</script>