<template>
<div class="container">
  <div class="row border py-2 mb-3">
    <display-field group-class="col-auto" label="Processor ID">{{procId}}</display-field>
    <display-field group-class="col-auto" label="Currency">{{currency}}</display-field>
  </div>
  
  <div class="row">
    <table class="table small">
      <thead class="table-dark">
        <tr>
          <th>Processor Code</th>
          <th>Bank Name</th>
          <th>System Code</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="bank in procBanks.bankList" :key="bank.procBankCode">
          <td>{{bank.procBankCode}}</td>
          <td><input-text v-model="bank.bankName"></input-text></td>
          <td><input-text v-model="bank.sysBankCode"></input-text></td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <div class="row">
    <div class="col-auto ms-auto">
      <button class="btn btn-secondary mx-2" @click.prevent="onCancel">Cancel</button>
      <button class="btn btn-primary mx-2" @click.prevent="onSave">Save</button>
    </div>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
    name: 'ProcBanksForm',
    mixins: [HelpersMixin],
    props: ['procId', 'currency'],
    data () {
        return {
            procBanks: {}
        }
    },
    mounted () {
        this.getProcBanks()
    },
    methods: {
        getProcBanks: function () {
            this.axios.get('/ax/procbanks/' + this.procId +  '/' + this.currency)
            .then(response => {
                this.procBanks = response.data
            })
            .catch (error => this.handleError(error))
        },
        onSave: function () {
            this.axios.post('/ax/procbanks', this.procBanks)
            .then(() => {
                this.$toasted.success('Bank codes for ' + this.procId + ' / ' + this.currency + ' updated')
                this.$emit('saved')
            })
            .catch(error => this.handleError(error))
        },
        onCancel: function () {
            this.$emit('cancelled')
        }
    }
}
</script>