<template>
<div class="container">
  <div class="row" v-if="mode !== 'add'">
    <display-field label="Merchant ID" group-class="col-auto">{{mx.merchantId}}</display-field>
  </div>
  <form>
  <fieldset :disabled="formDisabled">
    <input-text label="Merchant Name" v-model="mx.name" placeholder="enter merchant's full name"
                :errors="errors" error-key='name'/>
    <input-text label="Alias" v-model="mx.merchantAlias" placeholder="enter short name" group-class="col-md-6"
                :errors="errors" error-key="merchantAlias"/>
    <input-text label="Address" v-model="mx.address.addressLine1" placeholder="address line 1" group-class="mb-1"/>
    <input-text v-model="mx.address.addressLine2" placeholder="address line 2" group-class="mb-1"/>
    <div class="row">
    <input-text v-model="mx.address.cityTown" placeholder="city/town" group-class="col-md-6 mb-1"/>
    <input-text v-model="mx.address.stateProv" placeholder="state/province" group-class="col-md-6 mb-1"/>
    </div>
    <div class="row">
    <input-select v-model="mx.address.country" placeholder="select country" group-class="col-md-6 mb-1">
      <option v-for="c in countries" v-bind:key="c.code" :value="c.code">{{c.name}}</option>
    </input-select>
    <input-text v-model="mx.address.postalCode" placeholder="postal code" group-class="col-md-6"/>
    </div>
    <input-text label="Web Site" v-model="mx.webSite"/>
    <input-text label="Contact Person Name" v-model="mx.contactName"
                :errors="errors" error-key='contactName'/>
    <div class="row">
    <input-text label="Contact Email" v-model="mx.contactEmail" group-class="col-md-6"
                :errors="errors" error-key='contactEmail'/>
    <input-text label="Contact Phone" v-model="mx.contactPhone" group-class="col-md-6"
                :errors="errors" error-key='contactPhone'/>
    </div>
    
    <div class="row mt-2">
    <b-form-checkbox v-model="mx.notificationsEnabled" name="notification-switch" switch class="mt-2 col-auto">
      <span class="ps-2"> Email Notifications </span>
      <span v-if="mx.notificationsEnabled" class="text-success fw-semibold ps-1 small">ENABLED</span>
      <span v-else class="text-danger fw-semibold ps-2 small">DISABLED</span>
    </b-form-checkbox>
    <input-text v-if="mx.notificationsEnabled" v-model="mx.notificationEmail" placeholder="Notification Email" group-class="col-12"/>
    </div>

    <div class="row mt-2">
    <b-form-checkbox v-model="mx.callbacksEnabled" name="callbacks-switch" switch class="mt-2 col-auto">
      <span class="ps-2"> Callbacks </span>
      <span v-if="mx.callbacksEnabled" class="text-success fw-semibold ps-1 small">ENABLED</span>
      <span v-else class="text-danger fw-semibold ps-2 small">DISABLED</span>
    </b-form-checkbox>
    <input-text v-if="mx.callbacksEnabled" v-model="mx.defaultCallbackUrl" placeholder="Default Callback URL" group-class="col-12"/>
    </div>

    <div class="row mt-2">
    <input-text v-model="mx.defaultResultRedirectUrl" label="Default URL for Result Redirects" placeholder="Default Result URL" group-class="col-12"/>
    </div>
    
    <div class="row mt-2">
      <input-text label="IP Whitelist for API calls" v-model="mx.ipWhitelistForApi" group-class="col-6"/>
      <input-text label="IP Whitelist for Portal" v-model="mx.ipWhitelistForPortal" group-class="col-6"/>
    </div>
  </fieldset>
  </form>
  <div v-if="pleaseWait" class="mt-5 mb-3 text-center text-primary">
    <b-spinner variant='primary' label='Please wait'></b-spinner>
    <span class="ms-3 mt-1">Processing ... Please wait</span>
  </div>
  <div v-else class="text-end mt-5 mb-3">
    <button class="btn btn-secondary me-2" @click.prevent="onCancel">Cancel</button>
    <button class="btn btn-primary" @click.self.prevent="onSave">Save</button>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import { countriesList } from '../utils/Countries.js'
export default {
  name: 'MerchantForm',
  mixins: [HelpersMixin],
  props: ['merchantId'],
  data () {
    return {
      mx: { address: {}, notifyMerchant: false },
      countries: countriesList,
      formDisabled: false,
      pleaseWait: false,
      mode: 'add'
    }
  },
  mounted () {
    if (this.merchantId) {
      this.mode = 'update'
      this.getMerchant()
    }
  },
  methods: {
    getMerchant: function () {
      this.axios.get('/ax/merchant/' + this.merchantId)
      .then(response => {
        this.mx = response.data
        if (!this.mx.address) {
          this.mx.address = {}
        }
      })
      .catch(error => this.handleError(error))
    },
    onSave: function () {
      this.errors.clearAll()
      if (this.mode === 'add') {
        this.axios.put('/ax/merchant', this.mx)
        .then(() => {
          this.$emit('saved') 
          this.$toasted.success('Merchant ' + this.mx.merchantAlias + ' created.')
        })
        .catch(error => this.handleValidationErrors(error))
      } else {
        this.axios.post('/ax/merchant', this.mx)
        .then(() => {
          this.$emit('saved')
          this.$toasted.success('Merchant ' + this.mx.merchantAlias + ' updated.')
        })
        .catch(error => this.handleValidationErrors(error))
      }
    },
    onCancel: function () {
      this.$emit('cancelled')
    }
  }
}
</script>

