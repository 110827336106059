import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminLogin from '@/views/AdminLogin.vue'
import AdminPwChange from './views/AdminPwChange.vue'
import DashboardView from "@/views/DashboardView"
import TxnList from "@/views/TxnList"
import BalanceSummary from "@/views/BalanceSummary"
import MerchantList from '@/views/MerchantList'
import MerchantDetails from '@/views/MerchantDetails'
import ReferenceData from '@/views/ReferenceData'
import ProcList from '@/views/ProcList'
import PaymentChannels from '@/views/PaymentChannels'
import AdminUsers from '@/views/AdminUsers'
import AdminLedgerEntries from "@/views/AdminLedgerEntries"
import ProcBanksList from "@/views/ProcBanksList"
import store from './store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/transactions',
    name: 'txnlist',
    component: TxnList
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/balancesummary',
    name: 'balancesummary',
    component: BalanceSummary
  },
  {
    path: '/merchants',
    name: 'merchants',
    component: MerchantList
  },
  {
    path: '/merchant/:merchantId',
    name: 'merchant',
    component: MerchantDetails,
    props: true
  },
  {
    path: '/referencedata',
    name: 'referencedata',
    component: ReferenceData
  },
  {
    path: '/processors',
    name: 'processors',
    component: ProcList
  },
  {
    path: '/paymentchannels',
    name: 'paymentchannels',
    component: PaymentChannels
  },
  {
    path: '/adminusers',
    name: 'adminusers',
    component: AdminUsers
  },
  {
    path: '/adminledgerentries',
    name: 'adminledgerentries',
    component: AdminLedgerEntries
  },
  {
    path: '/procbanks',
    name: 'procbanks',
    component: ProcBanksList
  },
  {
    path: '/pwchange',
    name: 'pwchange',
    component: AdminPwChange
  },
  {
    path: '/login',
    name: 'login',
    component: AdminLogin
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next()
  } else {
    if (store.state.token && store.state.token.tokenId) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
