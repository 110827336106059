function ErrorsContainer () {
  this.fldErrors = []

  this.setFldErrors = function (fe) {
    for (var i = 0; i < fe.length; i++) {
      this.fldErrors.push(fe[i])
    }
  }

  this.getError = function (errorKey) {
    let err = this.fldErrors.find(o => o.field === errorKey)
    return err
  }

  this.addError = function (key, msg) {
    let err = { field: key, defaultMessage: msg }
    this.fldErrors.push(err)
  }

  this.clearAll = function () {
    this.fldErrors.splice(0, this.fldErrors.length)
  }
}

export { ErrorsContainer }
