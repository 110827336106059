<template>
<div class="container">
  <div class="row small fst-italic" v-if="mode === 'EDIT'">
    <div class="col-auto ms-auto text-warning">
      Last updated on <display-date :value="chform.lastUpdate" /> by {{chform.lastUpdateBy}}
    </div>
  </div>
  <div class="row mb-2">
    <input-text v-if="mode === 'ADD'" v-model="chform.channelCode" label="Channel Code" :errors="errors" error-key="channelCode" group-class="col-6"/>
    <display-field v-else label="Channel Code">{{channelCode}}</display-field>
  </div>
  <div class="row mb-2">
    <input-text v-model="chform.description" label="Description" :errors="errors" error-key="description"/>
  </div>
  <div class="row mb-2">
    <input-select v-model="chform.currency" label="Currency" group-class="col-auto">
      <option v-for="cur in currencies" :key="cur.refCode" :value="cur.refCode">{{cur.refCode}} - {{cur.refValue}}</option>
    </input-select>
  </div>
  <div class="row mb-2">
    <input-text v-model="chform.procId" label="Processor ID" :errors="errors" error-key="procId" group-class="col-6"/>
  </div>
  
  <div class="row bg-info mt-3">
    <div class="form-check ms-3 py-2">
      <input type="checkbox" class="form-check-input" v-model="payinSpecs.enabled"/>
      <label class="form-check-label" style="font-weight: 600;">Pay-In {{payinSpecs.enabled ? 'Enabled' : 'Disabled'}}</label>
    </div>
  </div>
  <template v-if="payinSpecs.enabled">
  <div class="border px-3 pb-3 small">
    <div class="row mb-2">
      <input-number v-model="payinFeeRule.flatFeeAmount" label="Txn Fee - Flat Amount" :append="chform.currency" :errors="errors" error-key="payinFeeRule.flatFeeAmount" group-class="col-auto"/>
      <input-number v-model="payinFeeRule.pctOfTxnAmount" label="Txn Fee - % of Txn Amount" append="%" :errors="errors" error-key="payinFeeRule.pctOfTxnAmount" group-class="col-auto"/>
    </div>
    <div class="row">
      <input-number v-model="payinSpecs.minTxnAmount" label="Minimum Pay-In" :errors="errors" error-key="payinSpecs.minTxnAmount" group-class="col-auto"/>
      <input-number v-model="payinSpecs.maxTxnAmount" label="Maximum Pay-In" :errors="errors" error-key="payinSpecs.maxTxnAmount" group-class="col-auto"/>
    </div>
    <div class="row mt-3 border pt-0 pb-2">
      <p class="col-12 fw-bold fst-italic text-info bg-secondary py-2">Required "endUserInfo" fields</p>
      <div class="col-3" v-for="fld in payinEndUserInfoFlds" :key="fld.name">
        <input type="checkbox" class="form-check-input" v-model="fld.required"/>
        <label class="form-check-label ms-2">{{fld.name}}</label>
      </div>
    </div>
    <div class="row border">
      <p class="col-12 mb-1 fw-bold fst-italic text-info bg-secondary py-2">Required "payInfo" fields</p>
      <div class="col-12 py-2">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Field Name</th>
              <th>Description</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(payInfoFld, index) in payinReqdPayInfo" :key="payInfoFld.fieldName">
              <td>
                <select class="form-select" v-model="payInfoFld.fieldName" @change="onFldChange(payInfoFld)">
                  <option v-for="(fld, refIndex) in refPayInfoFields" :key="refIndex" :value="fld.refCode">{{fld.refCode}}</option>
                </select>
              </td>
              <td>
                <input-text v-model="payInfoFld.description"/>
                <p v-if="payInfoFld.fieldName === 'bankCode'" class="mt-2">
                  <input type="checkbox" class="form-check-input" v-model="payinSpecs.validateBankCode"/>
                  <label class="form-check-label ms-2">Validate Bank Code</label>
                </p>
              </td>
              <td class="text-center"><a href="#" @click.prevent="onDelReqdPayInfo(payinReqdPayInfo, index)"><i class="fas fa-trash-alt px-2"></i></a></td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td class="text-center"><a href="#"><i class="fas fa-plus px-2" @click.prevent="onAddReqdPayInfo(payinReqdPayInfo)"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </template>
  
  <div class="row bg-info mt-3">
    <div class="form-check ms-3 py-2">
      <input type="checkbox" class="form-check-input" v-model="payoutSpecs.enabled"/>
      <label class="form-check-label" style="font-weight: 600;">Pay-Out {{payoutSpecs.enabled ? 'Enabled' : 'Disabled'}}</label>
    </div>
  </div>
  <template v-if="payoutSpecs.enabled">
  <div class="border px-3 pb-3 small">
    <div class="row mb-2">
      <input-number v-model="payoutFeeRule.flatFeeAmount" label="Txn Fee - Flat Amount" :append="chform.currency" :errors="errors" error-key="payoutFeeRule.flatFeeAmount" group-class="col-auto"/>
      <input-number v-model="payoutFeeRule.pctOfTxnAmount" label="Txn Fee - % of Txn Amount" append="%" :errors="errors" error-key="payoutFeeRule.pctOfTxnAmount" group-class="col-auto"/>
    </div>
    <div class="row">
      <input-number v-model="payoutSpecs.minTxnAmount" label="Minimum Pay-Out" :errors="errors" error-key="payoutSpecs.minTxnAmount" group-class="col-auto"/>
      <input-number v-model="payoutSpecs.maxTxnAmount" label="Maximum Pay-Out" :errors="errors" error-key="payoutSpecs.maxTxnAmount" group-class="col-auto"/>
    </div>
    <div class="row mt-3 border pt-0 pb-2">
      <p class="col-12 fw-bold fst-italic text-info bg-secondary py-2">Required "endUserInfo" fields</p>
      <div class="col-3" v-for="fld in payoutEndUserInfoFlds" :key="fld.name">
        <input type="checkbox" class="form-check-input" v-model="fld.required"/>
        <label class="form-check-label ms-2">{{fld.name}}</label>
      </div>
    </div>
    <div class="row border">
      <p class="col-12 mb-1 fw-bold fst-italic text-info bg-secondary py-2">Required "payInfo" fields</p>
      <div class="col-12 py-2">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Field Name</th>
              <th>Description</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(payInfoFld, index) in payoutReqdPayInfo" :key="payInfoFld.fieldName">
              <td>
                <select class="form-select" v-model="payInfoFld.fieldName" @change="onFldChange(payInfoFld)">
                  <option v-for="(fld, refIndex) in refPayInfoFields" :key="refIndex" :value="fld.refCode">{{fld.refCode}}</option>
                </select>
              </td>
              <td>
                <input-text v-model="payInfoFld.description"/>
                <p v-if="payInfoFld.fieldName === 'bankCode'" class="mt-2">
                  <input type="checkbox" class="form-check-input" v-model="payoutSpecs.validateBankCode"/>
                  <label class="form-check-label ms-2">Validate Bank Code</label>
                </p>
              </td>
              <td class="text-center"><a href="#" @click.prevent="onDelReqdPayInfo(payoutReqdPayInfo, index)"><i class="fas fa-trash-alt px-2"></i></a></td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td class="text-center"><a href="#"><i class="fas fa-plus px-2" @click.prevent="onAddReqdPayInfo(payoutReqdPayInfo)"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </template>
  
  <div class="row mt-4 bg-info py-2">
    <div class="col-auto" style="font-weight: 600;">Channel-Specific Processor Parameters</div>
  </div>
  <div class="border">
    <div class="row">
    <div class="col">
    <table class="table table-sm">
      <thead class="thead-dark small">
        <tr>
          <th width="30%">Parameter Name</th>
          <th width="65%">Parameter Value</th>
          <th width="5%">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, index) in chProcParams" :key="index">
          <td><input class="form-control form-control-sm" type="text" v-model="p.paramKey"/></td>
          <td><input class="form-control form-control-sm" type="text" v-model="p.paramValue"/></td>
          <td class="text-center"><a href="#"><i class="fas fa-trash-alt" @click="onDelParam(index)"></i></a></td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
          <td class="text-center"><a href="#"><i class="fas fa-plus" @click="onAddParam()"></i></a></td>
        </tr>
      </tbody>
    </table>
    </div>
    </div>
  </div>
  
  <div class="text-end py-3">
    <button class="btn btn-secondary me-2" @click.prevent="onCancel()">Cancel</button>
    <button class="btn btn-primary" @click.prevent="onSave()">Save</button>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'PaymentChannels',
  mixins: [HelpersMixin],
  props: ['action', 'channelCode'],
  data () {
    return {
      mode: '',
      chform: {},
      payinFeeRule: {currency: '', flatFeeAmount: 0, pctOfTxnAmount: 0},
      payoutFeeRule: {currency: '', flatFeeAmount: 0, pctOfTxnAmount: 0},
      payinSpecs: {enabled: true, minTxnAmount: 0, maxTxnAmount: 0, requiredEndUserInfo: [], requiredPayInfo: []},
      payoutSpecs: {enabled: false, minTxnAmount: 0, maxTxnAmount: 0, requiredEndUserInfo: [], requiredPayInfo: []},
      payinEndUserInfoFlds: [
        {name: 'firstName', desc: 'First Name', required: false},
        {name: 'lastName', desc: 'Last Name', required: false},
        {name: 'identifier', desc: 'End User ID', required: false},
        {name: 'emailAddress', desc: 'Email Address', required: false},
        {name: 'phoneNumber', desc: 'Phone Number', required: false},
        {name: 'address', desc: 'Address', required: false},
        {name: 'ipAddress', desc: 'IP Address', required: false}
      ],
      payinReqdPayInfo: [],
      payoutEndUserInfoFlds: [
        {name: 'firstName', desc: 'First Name', required: false},
        {name: 'lastName', desc: 'Last Name', required: false},
        {name: 'identifier', desc: 'End User ID', required: false},
        {name: 'emailAddress', desc: 'Email Address', required: false},
        {name: 'phoneNumber', desc: 'Phone Number', required: false},
        {name: 'address', desc: 'Address', required: false},
        {name: 'ipAddress', desc: 'IP Address', required: false}
      ],
      payoutReqdPayInfo: [],
      refPayInfoFields: [],
      chProcParams: [],
      currencies: []
    }
  },
  mounted () {
    this.getRefPayInfoFields()
    this.getCurrencies()
    
    if (this.action === 'EDIT') {
      this.mode = 'EDIT'
      this.getChannel()
    } else {
      this.mode = 'ADD'
      if (this.action === 'COPY') {
        this.getChannel()
      }
    }
  },
  methods: {
    getChannel: function () {
      this.axios.get('/ax/paymentchannel/' + this.channelCode)
      .then( response => {
        this.chform = response.data
        if (this.chform.payinFeeRule) {
            this.payinFeeRule = this.chform.payinFeeRule
        }
        if (this.chform.payoutFeeRule) {
            this.payoutFeeRule = this.chform.payoutFeeRule
        }
        this.payinSpecs = this.chform.payinSpecs
        this.payoutSpecs = this.chform.payoutSpecs
        if (this.payinSpecs.requiredPayInfo) {
          this.payinReqdPayInfo = this.payinSpecs.requiredPayInfo
        }
        if (this.payinSpecs.requiredEndUserInfo) {
          for (var piFld of this.payinSpecs.requiredEndUserInfo) {
            for (var piInputFld of this.payinEndUserInfoFlds) {
                if (piFld.fieldName === piInputFld.name) {
                    piInputFld.required = true
                }
            }
          }
        }
        if (this.payoutSpecs.requiredPayInfo) {
          this.payoutReqdPayInfo = this.payoutSpecs.requiredPayInfo
        }
        if (this.payoutSpecs.requiredEndUserInfo) {
          for (var poFld of this.payoutSpecs.requiredEndUserInfo) {
            for (var poInputFld of this.payoutEndUserInfoFlds) {
                if (poFld.fieldName === poInputFld.name) {
                    poInputFld.required = true
                }
            }
          }
        }
        if (this.chform.staticProcParams) {
          for (const [key, value] of Object.entries(this.chform.staticProcParams)) {
            this.chProcParams.push({paramKey: key, paramValue: value})
          }
        }
        if (this.mode === 'ADD') {
          this.chform.channelCode = ''
        }
      })
      .catch(error => this.handleError(error))
    },
    onSave: function () {
      if (this.payinReqdPayInfo) {
        this.payinSpecs.requiredPayInfo = this.payinReqdPayInfo
      }
      this.payinSpecs.requiredEndUserInfo = []
      for (var piInputFld of this.payinEndUserInfoFlds) {
        if (piInputFld.required) {
            this.payinSpecs.requiredEndUserInfo.push({fieldName: piInputFld.name, description: piInputFld.desc})
        }
      }
      if (this.payoutReqdPayInfo) {
        this.payoutSpecs.requiredPayInfo = this.payoutReqdPayInfo
      }
      this.payoutSpecs.requiredEndUserInfo = []
      for (var poInputFld of this.payoutEndUserInfoFlds) {
        if (poInputFld.required) {
            this.payoutSpecs.requiredEndUserInfo.push({fieldName: poInputFld.name, description: poInputFld.desc})
        }
      }
      this.paramsToMap()
      this.chform.payinSpecs = this.payinSpecs
      this.chform.payoutSpecs = this.payoutSpecs
      this.chform.payinFeeRule = this.payinFeeRule
      this.chform.payinFeeRule.currency = this.chform.currency
      this.chform.payoutFeeRule = this.payoutFeeRule
      this.chform.payoutFeeRule.currency = this.chform.currency
      if (this.mode === 'ADD') {
        this.axios.put('/ax/paymentchannel', this.chform)
        .then( () =>  this.$emit('saved') )
        .catch( error => this.handleError(error) )
      } else {
        this.axios.post('/ax/paymentchannel', this.chform)
        .then( () =>  this.$emit('saved') )
        .catch( error => this.handleError(error) )
      }
    },
    onCancel: function () {
      this.$emit('cancelled')
    },
    onAddReqdPayInfo: function(reqdPayInfo) {
      if (!reqdPayInfo) {
          reqdPayInfo = []
      }
      reqdPayInfo.push({fieldName: '', description: ''})
    },
    onDelReqdPayInfo: function(reqdPayInfo, index) {
      reqdPayInfo.splice(index, 1)
    },
    getRefPayInfoFields: function () {
      this.axios.get('/ax/refdata/reftype/PAYINFO_FIELDS')
      .then(response => {
        this.refPayInfoFields = response.data
      })
      .catch(error => this.handleError(error))
    },
    onAddParam: function () {
      this.chProcParams.push({ paramKey: '', paramValue: '' })
    },
    onDelParam: function (idx) {
      this.chProcParams.splice(idx, 1)
    },
    paramsToMap: function () {
      var paramsMap = {}
      for (var i = 0; i < this.chProcParams.length; i++) {
        paramsMap[this.chProcParams[i].paramKey] = this.chProcParams[i].paramValue
      }
      this.chform.staticProcParams = paramsMap
    },
    getCurrencies: function () {
      this.axios.get('/ax/refdata/reftype/FIAT_CURRENCIES')
      .then(response => {
        this.currencies = response.data
      })
      .catch(error => this.handleError(error))
    },
    onFldChange: function (payFld) {
      for (var fld of this.refPayInfoFields) {
        if (fld.refCode === payFld.fieldName) {
            payFld.description = fld.refValue
            break
        }
      }
    }
  }
}
</script>