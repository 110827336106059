<template>
<div class="input-group" :group-class="groupClass">
  <input-select v-model="merchant" :input-class="inputClass" :label="label" class="me-2">
    <option v-if="!excludeOptionAll" :value="allmerchants">{{allmerchants.name}}</option>
    <option v-for="mx in merchantList" :key="mx.merchantId" :value="mx">{{mx.name}} ({{mx.merchantId}})</option>
    <option v-if="includeSystem" :value="systementity">{{systementity.name}}</option>
  </input-select>
  <input-select v-model="account" :input-class="inputClass" v-if="!hideAccounts" :label="label ? 'Account' : ''">
    <option v-if="!excludeOptionAll" :value="allaccounts">{{allaccounts.accountName}}</option>
    <template v-if="merchant && merchant.accounts">
    <option v-for="acct in merchant.accounts" :key="acct.accountId" :value="acct">{{acct.accountName}} ({{acct.accountId}})</option>
    </template>
  </input-select>
</div>
</template>

<script>
export default {
  name: 'MerchantSelect',
  props: {
    inputClass: String,
    groupClass: String,
    label: String,
    excludeOptionAll: Boolean,
    includeSystem: Boolean,
    hideAccounts: Boolean,
    mxid: String
  },
  data () {
    return {
      systementity: {merchantId: 'SYSTEM', name: 'SYSTEM'},
      allmerchants: {merchantId: 'ALL', name: 'All Merchants'},
      allaccounts: {accountId: 'ALL', accountName: 'All Accounts'},
      merchantList: [],
      merchant: {merchantId: 'ALL', name: 'All Merchants', accounts: []},
      account: this.allaccounts,
      unwatchAccount: null
    }
  },
  watch: {
    mxid: function () {
      this.merchant = this.findMerchant(this.mxid)
    }
  },
  mounted () {
    this.getMerchants()
    if (this.mxid && this.mxid !== 'ALL') {
      this.merchant = this.findMerchant(this.mxid)
    } else if (this.excludeOptionAll) {
      this.merchant = {merchantId: '', name: ''}
      this.account = {accountId: '', accountName: ''}
    } else {
      this.merchant = this.allmerchants 
      this.account = this.allaccounts
    }
    this.$emit('merchantSelected', this.merchant)
    this.$watch('merchant', this.onMerchantSelected)
    this.unwatchAccount = this.$watch('account', this.onAccountSelected)
  },
  methods: {
    getMerchants: function () {
      if (this.$store.state.mxList) {
        this.merchantList = this.$store.state.mxList
        return
      }
      this.axios.get('/ax/merchant/selectlist')
      .then(response => {
        this.merchantList = response.data
        this.$store.commit('saveMxList', response.data)
        this.storeMxLookup()
      })
      .catch(error => {
        console.log(JSON.stringify(error))
        if (error.response && error.response.status === 401) {
          return
        }
        if (error.response.data) {
          this.$toasted.error(error.response.data.msgText)
        } else {
          this.$toasted.error('Error while retrieving merchants ... please try again later')
        }
      })
    },
    findMerchant: function (mxid) {
      for (const mx of this.merchantList) {
        if (mx.merchantId === mxid) {
          return mx
        }
      }
    },
    onMerchantSelected: function () {
      if (!this.merchant) {
        return
      }
      if (this.unwatchAccount) this.unwatchAccount()
      const selected = {merchantId: this.merchant.merchantId, accountId: this.excludeOptionAll ? '' : 'ALL'}  
      this.$emit('merchantSelected', selected)
      this.$nextTick(function () {
          if (this.excludeOptionAll) {
              this.account = {accountId: '', accountName: ''}              
          } else {
              this.account = this.allaccounts
          }
          this.unwatchAccount = this.$watch('account', this.onAccountSelected)
      })
    },
    onAccountSelected: function () {
        if (!this.merchant) {
          return
        }
        const selected = {merchantId: this.merchant.merchantId, accountId: this.account ? this.account.accountId : 'ALL'}  
        this.$emit('merchantSelected', selected)
    },
    storeMxLookup: function () {
      var mxLookup = {}
      for (const mx of this.merchantList) {
        mxLookup[mx.merchantId] = mx
      }
      this.$store.commit('saveMxLookup', mxLookup)
    }
  }
}
</script>