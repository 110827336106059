<template>
<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-8 mx-auto mt-5 border py-4 px-4">
      <div class="alert alert-danger" v-if="hasError">Invalid Login Credentials</div>
      <form v-on:submit.prevent="onLogin" action="">
        <input-text v-model="login.loginId" label="User ID"></input-text>
        <input-text v-model="login.loginPass" masked label="Password" group-class="mt-2"></input-text>
        <div class="text-end mt-3">
          <button type="submit" class="btn btn-primary">Login</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'AdminLogin',
  data () {
    return {
      login: { loginId: '', loginPass: ''},
      hasError: false
    }
  },
  methods: {
    onLogin: function () {
      this.hasError = false
      this.axios.post('/ax/login', this.login)
        .then(response => {
          this.$store.commit('saveToken', response.data)
          this.$router.push('/dashboard')
          this.$eventBus.$emit('logged-in')
        })
        .catch(error => {
          console.log(error.response.status + ': ' + error.response.data)
          this.hasError = true
        })
    }
  }
}
</script>