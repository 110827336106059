<template>
<div class="container-fluid">
  <div class="row">
    <display-field label="Record ID" group-class="col-6">{{entry.recordId}}</display-field>
    <display-field label="Posted By" group-class="col-6">{{entry.createdBy}}</display-field>
  </div>
  
  <div class="row">
    <display-field label="Create Date" group-class="col-6">
      <display-date :value="entry.createDate" timezone="UTC"/>
      <span class="pl-2 text-secondary small"><br/><display-date :value="entry.createDate"/></span>
    </display-field>
    <display-field label="Posting Date" group-class="col-6">
      <display-date :value="entry.postingDate" timezone="UTC"/>
      <span class="pl-2 text-secondary small"><br/><display-date :value="entry.postingDate"/></span>
    </display-field>
  </div>
  
  <div class="row">
    <display-field label="Merchant" group-class="col-6">
      <merchant-lookup :merchantId="entry.entityId"/>
    </display-field>
    <display-field label="Account" group-class="col-6">
      <account-lookup :merchantId="entry.entityId" :accountId="entry.accountId"/>
    </display-field>
  </div>
  
  <div class="row">
    <display-field label="Entry Type" group-class="col-6">{{entry.amountGroup}}</display-field>
    <display-field label="Amount" group-class="col-6">
      <span class="me-3 fw-bold text-white bg-success px-1" :class="{'bg-danger': entry.amountType === 'DR'}">{{entry.amountType}}</span>
      <display-amount :value="entry.amount" :currency="entry.currency"/>
    </display-field>
  </div>
  
  <div class="row">
    <display-field label="Description" group-class="col-12">{{entry.description}}</display-field>
  </div>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import MerchantLookup from '../components/MerchantLookup.vue'
import AccountLookup from '../components/AccountLookup.vue'
export default {
  name: 'LedgerEntryDetails',
  mixins: [HelpersMixin],
  components: { MerchantLookup, AccountLookup },
  props: ['recordId'],
  data () {
    return {
      entry: {}
    }
  },
  mounted () {
    this.getLedgerEntry()
  },
  methods: {
    getLedgerEntry: function () {
      this.axios.get('/ax/ledger/' + this.recordId)
      .then (response => {
        this.entry  = response.data
      })
      .catch (error => this.handleError(error))
    }
  }
}
</script>
