<template>
<div class="container">
    <div class="row">
      <p class="col-auto mx-auto alert alert-warning">You are setting this transaction to COMPLETED. Make sure you enter the correct Processed Amount</p>
    </div>
    <div class="row">
      <display-field label="Txn ID" group-class="col-auto">{{txnId}}</display-field>
    </div>
    <div class="row">
      <display-field label="Requested Amount"><display-amount-obj  :value="txn.txnAmount"/></display-field>
    </div>
    <div class="row">
      <input-number v-model="processedAmount" label="Processed Amount" group-class="col-auto" :append="currency"/>
    </div>
    <div class="row mt-3">
      <div class="col">
        <textarea v-model="comment" rows="3" class="form-control" placeholder="Enter comments (optional)"></textarea>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto ms-auto">
        <template v-if="updateInProgress">
          <b-spinner variant='primary' label='Please wait'></b-spinner>
          <span class="ms-3 mt-1">Processing ... Please wait</span>
        </template>
        <template v-else>
          <button class="btn btn-secondary me-2" @click.prevent="onAbort">Abort</button>
          <button class="btn btn-primary" @click.self.prevent="onSave">Save</button>
        </template>
      </div>
    </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'TxnCompletionForm',
  mixins: [HelpersMixin],
  props: [ 'txnId' ],
  data () {
    return {
      txn: {},
      processedAmount: 0,
      currency: '',
      comment: '',
      updateInProgress: false
    }
  },
  mounted () {
    this.getTxnDetails()
  },
  methods: {
    getTxnDetails: function () {
      this.axios.get('/ax/txn/' + this.txnId)
      .then(result => {
        this.txn = result.data
        this.currency = this.txn.txnAmount.currency
      })
      .catch(error => this.handleError(error))
    },
    onSave: function () {
      this.updateInProgress = true
      this.axios.post('/ax/txn/status/completed', {txnId: this.txnId, processedAmount: this.processedAmount, comment: this.comment})
      .then (response => {
        this.$toasted.success('Transaction ' + this.txnId + ' set to COMPLETED')
        this.$emit('saved', response.data)
      })
      .catch(error => { 
        this.updateInProgress = false
        this.handleError(error) 
      })
    },
    onAbort: function () {
      this.$emit('aborted')
    }
  }
}
</script>
