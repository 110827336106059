<template>
  <b-modal id="wait-modal" title="Please wait" 
    hide-footer 
    hide-header-close 
    no-close-on-backdrop 
    no-close-on-esc
    header-text-variant="white"
    header-bg-variant="info">
    <b-spinner variant="primary" label="Please wait"></b-spinner>
    <span class="mb-5 mx-3 text-danger" style="font-weight: 600">{{message ? message : 'Processing ... please wait'}}</span>
    <div class="pt-3">&nbsp;</div>
  </b-modal>
</template>

<script>
export default {
  name: 'WaitModal',
  props: {
    loading: Boolean,
    message: String
  },
  watch: {
    loading: function () {
      if (this.loading === true) {
        this.$bvModal.show('wait-modal')
      } else {
        this.$bvModal.hide('wait-modal')
      }
    }
  }
}
</script>
