<template>
<span>
  <span v-if="accountName != null">{{accountName}}
    <small class="text-secondary" v-if="!hideId"><br/>{{accountId}}</small>
  </span>
  <span v-else>{{merchantId}}</span>
</span>
</template>

<script>
export default {
  name: 'AccountLookup',
  props: { 
    merchantId: String, 
    accountId: String,
    hideId: { type: Boolean, default: false } 
  },
  computed: {
      accountName: function () {
          const mx = this.$store.state.mxLookup[this.merchantId]
          if (mx && mx.accounts) {
              for (const acct of mx.accounts) {
                  if (acct.accountId === this.accountId) {
                      return acct.accountName
                  }
              }
          }
          return null
      }
  }
}
</script>