<template>
<div class="container">
  <div class="row" v-if="isPermitted('AX_MERCHANTMAINT')">
    <div class="col-auto ms-auto small">
      <a @click.prevent="showAddForm()" href="#"><i class="fas fa-plus-square"></i> Add Channel</a>
    </div>
  </div>
  

  <div class="row py-2 mb-2">
    <input-select v-model="filterCurrency" group-class="col-auto">
      <option value="ALL">All Currencies</option>
      <option v-for="cur in currencies" :key="cur.refCode" :value="cur.refCode">{{cur.refCode}} - {{cur.refValue}}</option>
    </input-select>
    <input-select v-model="filterProc" group-class="col-auto">
      <option value="ALL">All Processors</option>
      <option v-for="proc in procIds" :key="proc" :value="proc">{{proc}}</option>
    </input-select>
  </div>

  <div class="row">
    <table class="table small table-hover" v-if="mxChannels.length > 0">
      <thead>
        <tr>
          <th width="15%">Channel Code</th>
          <th width="10%">Currency</th>
          <th width="10%">Processor</th>
          <th>Merchant-Specific Proc Attibs</th>
          <th width="20%">Last Updated</th>
          <th width="10%">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="mxch in mxChannels">
        <tr :key="mxch.channelCode" v-if="(filterCurrency === 'ALL' || getCurrency(mxch.channelCode) === filterCurrency) && (filterProc === 'ALL' || getProcessor(mxch.channelCode) === filterProc)">
          <td>{{mxch.channelCode}}</td>
          <td>{{getCurrency(mxch.channelCode)}}</td>
          <td>{{getProcessor(mxch.channelCode)}}</td>
          <td>{{mxch.attribs}}</td>
          <td><display-date :value="mxch.lastUpdate"/><br/><small>by {{mxch.lastUpdateBy}}</small></td>
          <td class="text-end">
            <a class="mx-1" @click.prevent="showUpdateForm(mxch.channelCode)" v-b-tooltip.hover title="Edit"><i class="fas fa-edit"></i></a>
            <a class="mx-1" @click.prevent="confirmChannelDel(mxch.channelCode)" v-b-tooltip.hover title="Delete"><i class="fas fa-trash text-danger"></i></a>
          </td>
        </tr>
        </template>
      </tbody>
    </table>
    <div v-else class="text-center text-warning">No Merchant Channels Found</div>
  </div>

  <b-modal id="mxch-form" title="Merchant Channels" hide-footer size="lg">
    <merchant-channel-form :merchant-id="merchantId" :channel-code="selectedChannel" :mode="formMode"
      @cancelled="$bvModal.hide('mxch-form')"
      @saved="formSaved">
    </merchant-channel-form>
  </b-modal>

  <b-modal id="confirmDelModal" title="Confirm Deletion" @ok="deleteMxChannel">
    <div class="text-center">
      <span class="fw-bold text-danger">Are you sure you want to remove channel {{selectedChannel}} ?</span>
    </div>
  </b-modal>

</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import MerchantChannelForm from '../components/MerchantChannelForm.vue'
export default {
  name: 'MerchantChannels',
  mixins: [HelpersMixin],
  components: { MerchantChannelForm },
  props: ['merchantId'],
  data () {
    return {
      mxChannels: [],
      selectedChannel: '',
      formMode: '',
      filterCurrency: 'ALL',
      filterProc: 'ALL',
      currencies: [],
      procIds: [],
      chLookup: []
    }
  },
  mounted () {
    this.getChannelLookup()
    this.getCurrencies()
    this.getProcIdList()
  },
  methods: {
    getMerchantChannels: function () {
      this.axios.get('/ax/merchantchannels/' + this.merchantId)
      .then (response => {
        this.mxChannels = response.data
      })
      .catch (error => this.handleError(error))
    },
    showAddForm: function () {
      this.formMode = 'add'
      this.selectedChannel = null
      this.$bvModal.show('mxch-form')
    },
    showUpdateForm: function (channelCode) {
      this.formMode = 'update'
      this.selectedChannel = channelCode
      this.$bvModal.show('mxch-form')
    },
    formSaved: function () {
      this.getMerchantChannels()
      this.$bvModal.hide('mxch-form')
    },
    confirmChannelDel: function (channelCode) {
      this.selectedChannel = channelCode
      this.$bvModal.show('confirmDelModal')
    },
    deleteMxChannel: function () {
      this.axios.delete('/ax/merchantchannel/' + this.merchantId + '/' + this.selectedChannel)
      .then(() => {
        this.getMerchantChannels()
        this.$toasted.success('Merchant Channel ' + this.selectedChannel + ' removed')
      })
      .catch(error => this.handleError(error))
    },
    getCurrency: function (channelCode) {
        if (this.chLookup && this.chLookup[channelCode]) {
            return this.chLookup[channelCode].currency
        }
        return '???'
    },
    getProcessor: function (channelCode) {
        if (this.chLookup && this.chLookup[channelCode]) {
            return this.chLookup[channelCode].procId
        }
        return '???'
    },
    getChannelLookup: function () {
        this.axios.get('/ax/channelsbasicinfo')
        .then (response => {
          const channelList = response.data
          for (const ch of channelList) {
              this.chLookup[ch.channelCode] = ch
          }
          this.getMerchantChannels()
        })
        .catch (error => this.handleError(error))
    },
    getCurrencies: function () {
        this.axios.get('/ax/refdata/reftype/FIAT_CURRENCIES')
        .then(response => {
          this.currencies = response.data
        })
        .catch(error => this.handleError(error))
      },
      getProcIdList: function () {
          this.axios.get('/ax/bdproclist')
          .then(response => {
            this.procIds = response.data
          })
          .catch(error => this.handleError(error))
      }
  }
}
</script>
