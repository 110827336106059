<template>
<div class="display-group mb-3" :class="mainClass">
  <div class="label-class text-info">{{label}}</div>
  <div class="slot-wrapper text-break">
  <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  name: 'DisplayField',
  props: {
    label: String,
    groupClass: String
  },
  data () {
    return {
      mainClass: '',
      slotwrapperelem: null
    }
  },
  mounted () {
    if (this.groupClass) {
      this.mainClass = this.groupClass
    } else {
      this.mainClass = 'col col-12'
    }
  }
}
</script>

<style scoped>
.label-class { font-size: 75%; margin-bottom: 1px; }
.display-group {  }
.slot-wrapper { min-height: 1.5em; background-color: #f1f3f4; padding-left: .5em; padding-right: .5em; border-radius: .5rem; border: 1px dotted; border-color: #f1f3f4; }
</style>