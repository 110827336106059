<template>
<div class="container">
  <page-title title="Merchants"/>

  <div class="row" v-if="isPermitted('AX_MERCHANTMAINT')">
    <div class="col-auto ms-auto small text-info"><a @click.prevent="createNew()" href="#"><i class="fas fa-plus-square"></i> Create New</a></div>
  </div>
  
  <div class="row">
      <table class="table table-striped table-hover small">
        <thead class="table-dark">
        <tr>
          <th>Merchant ID</th>
          <th>Alias</th>
          <th>Merchant Name</th>
          <th>Last Update</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="merchants.length === 0">
          <td colspan="5" class="text-info text-center fw-bold">No Records Found</td>
        </tr>
        <template v-else>
        <tr v-for="mx in merchants" v-bind:key="mx.merchantId" v-on:click="showDetails(mx.merchantId)" style="cursor: pointer;">
          <td>{{mx.merchantId}}</td>
          <td>{{mx.merchantAlias}}</td>
          <td>{{mx.name}}</td>
          <td><span v-if="mx.lastUpdate"> <display-date :value="mx.lastUpdate"/></span></td>
          <td>{{mx.status}}</td>
        </tr>
        </template>
        </tbody>
      </table>
  </div>
  
  <b-modal id="merchant-form" title="Create New Merchant" size="lg" hide-footer no-close-on-backdrop>
    <merchant-form @saved="onFormSaved()" @cancelled="$bvModal.hide('merchant-form')"></merchant-form>
  </b-modal>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import MerchantForm from '../components/MerchantForm.vue'
export default {
  name: 'MerchantList',
  mixins: [HelpersMixin],
  components: { MerchantForm },
  data () {
    return {
      merchants: []
    }
  },
  mounted () {
    this.getMerchants()
  },
  methods: {
    getMerchants: function () {
      this.axios.get('/ax/merchants')
      .then(response => {
        this.isLoading = false
        this.merchants = response.data
        this.merchants.sort((a,b) => {
            return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})
        })
      })
      .catch(error => {
        this.isLoading = false
        this.handleError(error)
      })
    },
    showDetails: function (merchantId) {
      this.$router.push('/merchant/' + merchantId)
    },
    createNew: function () {
      this.$bvModal.show('merchant-form')
    },
    onFormSaved: function () {
      this.$bvModal.hide('merchant-form')
      this.getMerchants()
    }
  }
}
</script>