<template>
<div class="container">
  <page-title title="Bank Codes"/>
  
  <div class="row">
    <table class="table table-striped table-hover small">
      <thead>
        <tr>
          <th>Processor ID</th>
          <th>Currency</th>
          <th>No. of Banks</th>
          <th>Last Updated</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(pb, idx) in procBanksList" :key="idx">
          <td>{{pb.procId}}</td>
          <td>{{pb.currency}}</td>
          <td>{{pb.numberOfBanks}}</td>
          <td><display-date :value="pb.lastUpdate"/></td>
          <td><a href="#" @click="showUpdateForm(pb.procId, pb.currency)"><i class="fa-solid fa-bars"></i></a></td>
        </tr>
      </tbody>
    </table>
  </div>

  <b-modal id="pb-form" title="Bank Codes" hide-footer size="lg">
    <proc-banks-form :procId="selProcId" :currency="selCurrency"
      @saved="onFormSaved" @cancelled="$bvModal.hide('pb-form')">
    </proc-banks-form>
  </b-modal>

</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcBanksForm from '../components/ProcBanksForm.vue'
export default {
    name: 'ProcBanksList',
    mixins: [HelpersMixin],
    components: { ProcBanksForm },
    data () {
        return {
            procBanksList: [],
            selProcId: '',
            selCurrency: ''
        }
    },
    mounted () {
        this.getList()
      },
    methods: {
        getList: function () {
            this.axios.get('/ax/procbanks')
            .then(response => {
              this.procBanksList = response.data
            })
            .catch(error => {
              this.handleError(error)
            })
        },
        showUpdateForm: function (procId, currency) {
            this.selProcId = procId
            this.selCurrency = currency
            this.$bvModal.show('pb-form')
        },
        onFormSaved: function () {
            this.$bvModal.hide('pb-form')
        }
    }
}
</script>