<template>
<div class="container">
  <page-title title="Merchant Details"/>
  <div class="row text-primary">
    <div class="col-auto mx-auto fw-bold">{{merchantId}} - {{mx.name}}</div>
  </div>
  
  <div class="row">
    <router-link to="/merchants" class="col small text-info ms-0 ps-0 mb-2"><i class="fas fa-arrow-left"></i> Back to List</router-link>
  </div>
  
  <div class="row justify-content-left">
    <div class="card w-100 px-0">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs fw-bold">
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(1) }" v-on:click="tabNum = 1">Profile</a></li>
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(2) }" v-on:click="tabNum = 2">Accounts</a></li>
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(3) }" v-on:click="tabNum = 3">Users</a></li>
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(4) }" v-on:click="tabNum = 4">Channels</a></li>
        </ul>
      </div>
      <div class="card-body">
        <merchant-profile :merchant-id='merchantId' v-if="tabNum === 1" @retrieved="onMxRetrieved"></merchant-profile>
        <merchant-accounts :merchant-id='merchantId' v-if="tabNum === 2"></merchant-accounts>
        <merchant-users :merchant-id='merchantId' v-if="tabNum === 3"></merchant-users>
        <merchant-channels :merchant-id='merchantId' v-if="tabNum === 4"></merchant-channels>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import MerchantProfile from '../components/MerchantProfile.vue'
import MerchantUsers from '../components/MerchantUsers.vue'
import MerchantAccounts from '../components/MerchantAccounts.vue'
import MerchantChannels from '../components/MerchantChannels.vue'
export default {
  name: 'MerchantDetails',
  mixins: [HelpersMixin],
  components: {
    MerchantProfile,
    MerchantUsers,
    MerchantAccounts,
    MerchantChannels
  },
  props: ['merchantId'],
  data () {
    return {
      tabNum: 1,
      mx: {}
    }
  },
  methods: {
    isActive: function (n) {
      if (n === this.tabNum) {
        return true
      }
      return false
    },
    onMxRetrieved: function(event) {
      this.mx = event
    }
  }
}
</script>