<template>
<div class="container-fluid">
  <div class="row text-white bg-dark mb-2">
    <h4 class="col-auto pt-2 me-auto">CONNECX
      <span v-if="isTestEnv" class="text-danger font-weight-bold ms-5 fs-6">TEST ENVIRONMENT</span>
    </h4>

    <template v-if="isLoggedIn()">
    <div class="col-auto mx-0 px-1">
      <button type="button" class="btn btn-secondary btn-sm mt-2 mx-0" v-b-tooltip.hover title="Dashboard">
        <router-link to="/dashboard"><i class="fa fa-tachometer-alt text-white"></i></router-link>
      </button>
    </div>
    <div class="col-auto mx-0 ps-1 pe-3">
      <button type="button" class="btn btn-secondary btn-sm mt-2 mx-0" v-b-tooltip.hover title="Transactions">
        <router-link to="/transactions"><i class="fa fa-list text-white"></i></router-link>
      </button>
    </div>
    <div class="col-auto mx-0 ps-1 pe-3">
      <button type="button" class="btn btn-secondary btn-sm mt-2 mx-0" v-b-tooltip.hover title="Balances">
        <router-link to="/balancesummary"><i class="fa fa-sack-dollar text-white"></i></router-link>
      </button>
    </div>
    <div class="col-auto dropdown mx-0 px-1">
      <button class="btn btn-secondary btn-sm dropdown-toggle mt-2 me-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fas fa-cogs"></i>
      </button>
      <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenuButton1">
        <li class="bg-info py-2 mt-0 text-white fw-bold text-center">Settings</li>
        <li>
          <router-link to="/merchants" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Merchants</router-link>
        </li>
        <li>
          <router-link to="/referencedata" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Reference Data</router-link>
        </li>
        <li>
          <router-link to="/processors" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Processors</router-link>
        </li>
        <li>
          <router-link to="/paymentchannels" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Payment Channels</router-link>
        </li>
        <li>
          <router-link to="/procbanks" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Bank Codes</router-link>
        </li>
        <li>
          <router-link to="/adminledgerentries" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Admin Ledger Entries</router-link>
        </li>
        <li v-if="isPermitted('AX_ADMINUSERMAINT')">
          <router-link to="/adminusers" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Admin Users</router-link>
        </li>
      </ul>
    </div>
    <div class="col-auto dropdown mx-0 px-1">
      <button class="btn btn-secondary btn-sm dropdown-toggle mt-2 ms-0" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-user-circle"></i>
      </button>
      <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenuButton2">
        <li class="bg-info py-2 mt-0 text-white fw-bold text-center">{{$store.state.token.userId}}</li>
        <li><router-link to="/pwchange" class="dropdown-item"><i class="fa fa-lock me-2"></i> Change Password</router-link></li>
        <li><a class="dropdown-item" href="#" @click.prevent="logout"><i class="fa fa-power-off me-2"></i>Logout</a></li>
      </ul>
    </div>
    </template>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'AdminHeader',
  mixins: [HelpersMixin],
  data () {
    return {
      isTestEnv: false
    }
  },
  mounted () {
    if (window.location.hostname.includes('localhost') || window.location.hostname.includes('test')) {
      this.isTestEnv = true
    }
  },
  methods: {
    isLoggedIn: function () {
      if (this.$store.state.token && this.$store.state.token.tokenId) {
        return true
      } else {
        return false
      }
    },
    logout: function () {
      this.axios.get('/ax/logout')
      .then(() => {
        this.$store.commit('clearToken')
        this.$router.push('/login')
      })
      .catch(error => {
        this.handleError(error)
      })
    }
  }
}
</script>

<style scoped>
.dropdown-item { font-size: .8em; border-top: 1px solid #d3d3d3; padding-top: .8em; padding-bottom: .8em;}
</style>

