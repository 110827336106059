<template>
<div class="container">
  <div class="row mb-2">
    <merchant-select @merchantSelected="onMerchantSelected" group-class="col-auto" label="Merchant" excludeOptionAll/>
  </div>
  
  <div class="row mb-2">
    <input-select v-model="entryForm.amountGroup" group-class="col-auto" label="Entry Type">
      <option value="SETTLEMENTS">Settlement</option>
      <option value="FEES">Fees</option>
      <option value="ADJ_DEBITS">Debit Adjustment</option>
      <option value="ADJ_CREDITS">Credit Adjustment</option>
    </input-select>
  </div>
  
  <div class="row mb-2">
    <input-date v-model="entryForm.postingDate" group-class="col-auto" label="Posting Date"/>
  </div>
  
  <div class="row mb-3">
    <input-select v-model="entryForm.currency" group-class="col-auto" label="Currency">
      <option v-for="cur in currencies" :key="cur.refCode" :value="cur.refCode">{{cur.refCode}} - {{cur.refValue}}</option>
    </input-select>
    <input-number v-model="entryForm.amount" group-class="col-auto" label="Amount"/>
  </div>
  
  <div class="row">
    <div class="col-auto" style="font-size: 75%; margin-bottom: 1px; font-weight: 600;">Description</div>
  </div>
  <div class="row mb-2">
    <div class="col-12">
      <textarea rows="5" v-model="entryForm.description" class="form-control"></textarea>
    </div>
  </div>
  
  <div class="row mt-3">
    <button class="btn btn-secondary col-auto ms-auto me-2" @click.prevent="onCancel" >Cancel</button>
    <button class="btn btn-primary col-auto me-2" @click.prevent="onSubmit">Submit</button>
  </div>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import MerchantSelect from '../components/MerchantSelect.vue'
export default {
  name: 'AdminLedgerEntryForm',
  mixins: [HelpersMixin],
  components: { MerchantSelect },
  data () {
    return {
      entryForm: { entityId: null, accountId: null, amountGroup: null, currency: null, amount: null, postingDate: null, description: null },
      currencies: []
    }
  },
  mounted () {
    this.getCurrencies()
  },
  methods: {
    onSubmit: function () {
      this.axios.put('/ax/adminledgerentry', this.entryForm)
      .then (response => {
        const ledgerEntry = response.data
        this.$toasted.success('Ledger entry successfully created')
        this.$emit('saved', ledgerEntry)
      })
      .catch (error => this.handleError(error))
    },
    onCancel: function () {
      this.$emit('cancelled')
    },
    onMerchantSelected: function (eventData) {
      if (eventData && eventData.merchantId) {
        this.entryForm.entityId = eventData.merchantId
        this.entryForm.accountId = eventData.accountId
      }
    },
    getCurrencies: function () {
      this.axios.get('/ax/refdata/reftype/FIAT_CURRENCIES')
      .then(response => {
        this.currencies = response.data
      })
      .catch(error => this.handleError(error))
    }
  }
}
</script>
