<template>
<div>
  <div class="container" v-if="view === 'list'">
    <page-title title="Processors"/>
  
    <div class="row">
      <div class="col-auto ms-auto small text-info"><a @click.prevent="createNew()" href="#"><i class="fas fa-plus-square"></i> Create New</a></div>
    </div>
    
    <div class="row">
      <table class="table table-striped table-hover small">
        <thead class="table-dark">
          <tr>
          <th>Proc ID</th>
          <th>Type</th>
          <th>Description</th>
          <th>Last Update</th>
          <th>Last Update By</th>
          <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!procList || procList.length === 0">
            <td colspan="6" class="text-warning text-center py-3">No Processors Found</td>
          </tr>
          <template v-for="proc in sortByProcId(procList)">
          <tr v-bind:key="proc.procId" @click="showDetails(proc)" style="cursor: pointer;" v-if="showAll || proc.active">
            <td>{{proc.procId}}</td>
            <td>{{proc.procType}}</td>
            <td>{{proc.desc}}</td>
            <td><display-date :value="proc.lastUpdate"/></td>
            <td>{{proc.lastUpdateBy}}</td>
            <td>
              <span v-if="proc.active" class="text-success">Active</span>
              <span v-else class="text-warning">Inactive</span>
            </td>
          </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
  
  <proc-form v-if="view === 'form'" :proc="selected"
    v-on:saved="getProcList()" @cancelled="view = 'list'">
  </proc-form>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcForm from '../components/ProcForm.vue'
export default {
  name: 'ProcList',
  mixins: [HelpersMixin],
  components: { ProcForm },
  data () {
    return {
      procList: [],
      view: 'list',
      showAll: true,
      selected: null
    }
  },
  mounted () {
    this.getProcList()
  },
  methods: {
    getProcList: function () {
      this.view = 'list'
      this.axios.get('/ax/procattribs')
      .then(response => {
        this.procList = response.data
      })
      .catch(error => {
        this.handleError(error)
      })
    },
    createNew: function () {
      this.selected = null
      this.view = 'form'
    },
    showDetails: function (proc) {
      this.selected = proc
      this.view = 'form'
    },
    sortByProcId: function (procList) {
      if (!procList) {
        return null
      }
      return Array.from(Object.values(procList)).sort((a, b) => (a.procId.toLowerCase() > b.procId.toLowerCase()) ? 1 : -1)
    }
  }
}
</script>