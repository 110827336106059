<template>
<div class="row">
  <div class="col-auto mx-auto">
    <b-spinner variant="primary" label="Please wait"></b-spinner>
    <span class="ms-2 text-danger">{{msgText}}</span>
  </div>
</div>
</template>

<script>
export default {
  name: 'WaitInline',
  props: { message: String },
  computed: {
    msgText: function () {
      if (this.message) {
        return this.message
      } else {
        return 'Please wait'
      }
    }
  }
}
</script>