<template>
<div class="container-fluid">
  <div class="row mb-3">
    <display-field label="Transaction ID" group-class="col-auto fw-bold">{{txn.txnId}}</display-field>
    <div v-if="txn.txnType === 'BD_PAY_IN'" class="col-auto ms-auto fw-bold text-white bg-success my-1 py-3">PAY-IN</div>
    <div v-if="txn.txnType === 'BD_PAY_OUT'" class="col-auto ms-auto fw-bold text-white bg-danger my-1 py-3">PAY-OUT</div>
  </div>
  <div class="row">
    <display-field label="Status" group-class="col-6 fw-bold">{{txn.currentStatus}}
      <a v-if="txn.currentStatus === 'FORAUTHZ' || txn.currentStatus === 'PENDING'" href="#" @click.prevent="showConfirmDlg('CANCEL')" v-b-tooltip.hover title="Cancel Transaction">
        <button class="btn btn-sm btn-danger ms-2 py-0 my-2"><i class="fa-regular fa-circle-stop"></i></button>
      </a>
      <a v-if="txn.currentStatus === 'CANCELLED' || txn.currentStatus === 'FAILED'" href="#" @click.prevent="showConfirmDlg('RESET')" v-b-tooltip.hover title="Reset to INPROCESS">
        <button class="btn btn-sm btn-danger ms-2 py-0 my-2"><i class="fa-solid fa-backward-step"></i></button>
      </a>
      <a v-if="txn.currentStatus === 'INPROCESS' || txn.currentStatus === 'FORAUTHZ' || txn.currentStatus === 'PENDING'" href="#" @click.prevent="showConfirmDlg('RESYNC')" v-b-tooltip.hover title="Sync with Processor Result">
        <button class="btn btn-sm btn-danger ms-2 py-0 my-2"><i class="fa-solid fa-rotate"></i></button>
      </a>
      <a v-if="txn.currentStatus === 'INPROCESS'" href="#" @click.prevent="showCompletionForm" v-b-tooltip.hover title="Set Transaction to COMPLETED">
        <button class="btn btn-sm btn-danger ms-2 py-0 my-2"><i class="fa-solid fa-file-circle-check"></i></button>
      </a>
    </display-field>
    <display-field label="Transaction Result" group-class="col-6">
      <span class="fw-bold">{{txn.txnResultCode}}</span><br/>
      <small>{{txn.txnResultMesssage}}</small>
    </display-field>
  </div>
  <div class="row">
    <display-field label="Requested Amount" group-class="col-4 fw-bold"><display-amount-obj :value="txn.txnAmount"/></display-field>
    <display-field label="Processed Amount" group-class="col-4 fw-bold"><display-amount-obj :value="txn.processedAmount"/></display-field>
    <display-field label="Transaction Fee" group-class="col-4 fw-bold"><display-amount-obj :value="txn.txnFee"/></display-field>
    
    <display-field label="Request Date" group-class="col-6">
      <display-date :value="txn.createDate" timezone="UTC"/>
      <span class="pl-2 text-secondary small"><br/><display-date :value="txn.createDate"/></span>
    </display-field>
    <display-field label="Status Date" group-class="col-6">
      <display-date :value="txn.currentStatusDate" timezone="UTC"/>
      <span class="pl-2 text-secondary small"><br/><display-date :value="txn.currentStatusDate"/></span>
    </display-field>

    <display-field label="Channel Code" group-class="col-6">{{txn.channelCode}}</display-field>
    <display-field label="Processor" group-class="col-6">{{txn.procId}}</display-field>
    <display-field label="Processor Reference" group-class="col-6">{{txn.procRef}}</display-field>
    <display-field label="Processor Result" group-class="col-6">
      <a href="#" @click.prevent="$bvModal.show('proc-result')" class="me-2" v-b-tooltip.hover title="Check Processor Result"><i class="fa-solid fa-binoculars text-info"></i></a>
      {{txn.procResultCode}}<br/><small>{{txn.procResultText}}</small>
    </display-field>

    <display-field label="Merchant ID" group-class="col-6"><merchant-lookup :merchantId="txn.merchantId"/></display-field>
    <display-field label="Account ID" group-class="col-6"><account-lookup :merchantId="txn.merchantId" :accountId="txn.accountId"/></display-field>
    <display-field label="Merchant Payment Reference" group-class="col-12">{{txn.paymentRef}}</display-field>
    <display-field label="Merchant Trace Data" group-class="col-12">{{txn.merchantTraceData}}</display-field>
    <display-field label="Result URL">{{txn.resultUrl}}</display-field>
  </div>
  <div class="row bg-secondary text-info">
    <div class="col small fst-italic border border-info">End-User Info</div>
  </div>
  <div class="row border border-info pt-2">
    <display-field label="End-User Identifier" group-class="col-6"><span v-if="txn.endUserInfo">{{txn.endUserInfo.identifier}}</span></display-field>
    <display-field label="User IP Address" group-class="col-6"><span v-if="txn.endUserInfo">{{txn.endUserInfo.ipAddress}}</span></display-field>
    <display-field label="Phone Number" group-class="col-6"><span v-if="txn.endUserInfo">{{txn.endUserInfo.phoneNumber}}</span></display-field>
    <display-field label="Email Address" group-class="col-6"><span v-if="txn.endUserInfo">{{txn.endUserInfo.emailAddress}}</span></display-field>
    <display-field label="End-User Name" group-class="col-12"><span v-if="txn.endUserInfo">{{txn.endUserInfo.firstName}} {{txn.endUserInfo.lastName}}</span></display-field>
    <display-field label="Address" group-class="col-12">
      <span v-if="txn.endUserInfo && txn.endUserInfo.address">
        {{txn.endUserInfo.address.addressLine1}} {{txn.endUserInfo.address.addressLine2}}<br/>
        {{txn.endUserInfo.address.cityTown}} {{txn.endUserInfo.address.stateProv}}<br/>
        {{txn.endUserInfo.address.country}} {{txn.endUserInfo.address.postalCode}}
      </span>
      <span v-else>--</span>
    </display-field>
  </div>
  <div class="row bg-secondary text-info mt-2" v-if="txn.paymentInfo">
    <div class="col small fst-italic border border-info">Payment Info</div>
  </div>
  <div class="row border border-info pt-2" v-if="txn.paymentInfo">
    <div class="col">
      <ul>
        <li v-for="(value, key, index) in txn.paymentInfo" :key="index">
          {{key}} = {{value}}
        </li>
      </ul>
    </div>
  </div>
  <div class="row bg-secondary text-info mt-2">
    <div class="col small fst-italic border border-info">Processor Response Info</div>
  </div>
  <div class="row border border-info pt-2">
    <display-field label="Processor Result Code and Text">{{txn.procResultCode}} - {{txn.procResultText}}</display-field>
    <display-field label="Raw Processor Response"><div class="small text-warning">{{txn.rawProcResponse}}</div></display-field>
    <display-field label="Comments" v-if="txn.comments">
      <p v-for="(comment, index) in txn.comments" :key="index" class="small ms-0">{{comment}}</p>
    </display-field>
  </div>
  
  <b-modal id="confirm-dlg" :title="confirmTitle" size="lg" hide-footer no-close-on-backdrop>
    <div class="row">
      <p class="col-auto mx-auto alert alert-danger">{{confirmMsg}}</p>
    </div>
    <div class="row">
      <textarea v-model="updateComment" rows="3" class="col-auto form-control" placeholder="Enter comments (optional)"></textarea>
    </div>
    <div class="row mt-3">
      <div class="col-auto ms-auto">
        <template v-if="updateInProgress">
          <b-spinner variant='primary' label='Please wait'></b-spinner>
          <span class="ms-3 mt-1">Processing ... Please wait</span>
        </template>
        <template v-else>
          <button class="btn btn-secondary me-2" @click.prevent="abortConfirm">Abort</button>
          <button class="btn btn-primary" @click.self.prevent="confirmFxn">Proceed</button>
        </template>
      </div>
    </div>
  </b-modal>
  
  <b-modal id="proc-result" size="lg" hide-footer title="Processor Result" header-bg-variant="info">
    <proc-result :txn-id="txnId"/>
  </b-modal>
  
  <b-modal id="completion-form" hide-footer title="Transaction Completion" header-bg-variant="info">
    <txn-completion-form :txn-id="txnId" @saved="onCompletionSaved" @aborted="$bvModal.hide('completion-form')"/>
  </b-modal>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcResult from '../components/ProcResult.vue'
import TxnCompletionForm from '../components/TxnCompletionForm.vue'
import MerchantLookup from '../components/MerchantLookup.vue'
import AccountLookup from '../components/AccountLookup.vue'
export default {
  name: 'TxnDetails',
  mixins: [HelpersMixin],
  props: [ 'txnId' ],
  components: { ProcResult, TxnCompletionForm, MerchantLookup, AccountLookup },
  data () {
    return {
      txn: { createDate: {}, statusDate: {}, txnAmount: {value: 0} },
      confirmOper: '',
      confirmTitle: '',
      confirmMsg: '',
      confirmFxn: null,
      updateComment: '',
      updateInProgress: false
    }
  },
  mounted () {
    this.getTxnDetails()
  },
  methods: {
    getTxnDetails: function () {
      this.axios.get('/ax/txn/' + this.txnId)
      .then(result => {
        this.txn = result.data
      })
      .catch(error => this.handleError(error))
    },
    showConfirmDlg: function (oper) {
      this.updateInProgress = false
      if (oper === 'CANCEL') {
        this.confirmTitle = 'Cancel Transaction'
        this.confirmMsg = 'Confirm cancellation of transaction ' + this.txnId
        this.confirmFxn = this.processCancellation
      } else if (oper === 'RESET') {
        this.confirmTitle = 'Reset Transaction'
        this.confirmMsg = 'Confirm resetting status to INPROCESS for transaction ' + this.txnId
        this.confirmFxn = this.processReset
      } else if (oper === 'RESYNC') {
        this.confirmTitle = 'Resync Transaction'
        this.confirmMsg = 'Confirm syncing status with processor result for transaction ' + this.txnId
        this.confirmFxn = this.processResync
      }
      this.$bvModal.show('confirm-dlg')
    },
    abortConfirm: function () {
      this.$bvModal.hide('confirm-dlg')
    },
    processCancellation: function () {
      this.updateInProgress = true
      this.axios.post('/ax/txn/cancel', {txnId: this.txnId, comment: this.updateComment})
      .then(result => {
        this.txn = result.data
        this.$toasted.success('Transaction ' + this.txnId + ' cancelled')
        this.$bvModal.hide('confirm-dlg')
        this.$emit('dtlChanged', this.txn)
      })
      .catch(error => { 
        this.updateInProgress = false
        this.handleError(error) 
      })
    },
    processReset: function () {
      this.updateInProgress = true
      this.axios.post('/ax/txn/status/reset', {txnId: this.txnId, comment: this.updateComment})
      .then(result => {
        this.txn = result.data
        this.$toasted.success('Transaction ' + this.txnId + ' status reset to INPROCESS')
        this.$bvModal.hide('confirm-dlg')
        this.$emit('dtlChanged', this.txn)
      })
      .catch(error => { 
        this.updateInProgress = false
        this.handleError(error) 
      })
    },
    processResync: function () {
      this.updateInProgress = true
      this.axios.post('/ax/txn/status/resync', {txnId: this.txnId, comment: this.updateComment})
      .then(result => {
        this.txn = result.data
        this.$toasted.success('Transaction ' + this.txnId + ' status synced with processor result')
        this.$bvModal.hide('confirm-dlg')
        this.$emit('dtlChanged', this.txn)
      })
      .catch(error => { 
        this.updateInProgress = false
        this.handleError(error) 
      })
    },
    showCompletionForm: function () {
      this.$bvModal.show('completion-form')
    },
    onCompletionSaved: function (eventData) {
      this.txn = eventData
      this.$emit('dtlChanged', this.txn)
      this.$bvModal.hide('completion-form')
    }
  }
}
</script>