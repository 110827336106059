<template>
<div class="container">
  <div class="row" v-if="isPermitted('AX_MERCHANTMAINT')">
    <div class="col-auto ms-auto">
      <a @click.prevent="$bvModal.show('merchant-form')"><i class="fas fa-edit"></i></a>
    </div>
  </div>
  
  <div class="row">
    <display-field label="Merchant ID" group-class="col-md-4">{{mx.merchantId}}</display-field>
    <display-field label="Merchant Alias" group-class="col-md-4">{{mx.merchantAlias}}</display-field>
    <display-field label="Status" group-class="col-md-4">{{mx.status}}</display-field>
  </div>
  <div class="row">
    <display-field label="Merchant Name">{{mx.name}}</display-field>
  </div>
  <div class="row">
    <display-field label="API Key">{{mx.apiKey}}
      <span class="ps-3 text-info"><a @click="confirmApiKeyRegen()"><i class="fas fa-sync"></i></a></span>
    </display-field>
  </div>
  <div class="row">
    <display-field label="Address">
      <span v-if="mx.address">
        {{mx.address.addressLine1}} {{mx.address.addressLine2}}<br />
        {{mx.address.cityTown}}<span v-if="mx.address.cityTown">,</span> {{mx.address.stateProv}}<br />
        {{mx.address.postalCode}} {{mx.address.country}}
      </span>&nbsp;
    </display-field>
  </div>
  <div class="row">
    <display-field label="Web Site">{{mx.webSite}}</display-field>
    <display-field label="Contact">
      <span v-if="mx.contactName">{{mx.contactName}}<br/></span>
      <span v-if="mx.contactEmail">{{mx.contactEmail}}<br/></span>
      <span v-if="mx.contactPhone">{{mx.contactPhone}}</span>
    </display-field>
  </div>
  <div class="row">
    <display-field label="Email Notifications" group-class="col-12">
      <span v-if="mx.notificationsEnabled">{{mx.notificationEmail}}</span>
      <span v-else>DISABLED</span>
    </display-field>
  </div>
  <div class="row">
    <display-field :label="mx.callbacksEnabled ? 'Default Callback URL' : 'Callbacks'" group-class="col-12">
      <span v-if="mx.callbacksEnabled">{{mx.defaultCallbackUrl}}</span>
      <span v-else>DISABLED</span>
    </display-field>
  </div>
  <div class="row">
    <display-field label="Default URL for Result Redirects" group-class="col-12">
      {{mx.defaultResultRedirectUrl}}
    </display-field>
  </div>
  <div class="row">
    <display-field label="IP Whitelist for API calls" group-class="col-6">{{mx.ipWhitelistForApi}}</display-field>
    <display-field label="IP Whitelist for Portal" group-class="col-6">{{mx.ipWhitelistForPortal}}</display-field>
  </div>
  
  <div class="row">
    <display-field label="Created On" group-class="col-md-6">
      <span v-if="mx.createDate"><display-date :value="mx.createDate"/></span>
    </display-field>
    <display-field label="Last Update" group-class="col-md-6">
      <span v-if="mx.lastUpdate"><display-date :value="mx.lastUpdate"/></span>
      <span v-if="mx.lastUpdateBy"> by {{mx.lastUpdateBy}}</span>&nbsp;
    </display-field>
  </div>

  <b-modal id="merchant-form" title="Update Merchant Profile" size="lg" hide-footer no-close-on-backdrop>
    <merchant-form @saved="profileUpdated()" @cancelled="$bvModal.hide('merchant-form')" :merchant-id="merchantId"></merchant-form>
  </b-modal>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import MerchantForm from '../components/MerchantForm.vue'
export default {
  name: 'MerchantProfile',
  mixins: [HelpersMixin],
  components: { MerchantForm },
  props: ['merchantId'],
  data () {
    return {
      mx: {}
    }
  },
  mounted () {
    this.getMerchantDetail()
  },
  methods: {
    getMerchantDetail: function () {
      this.axios.get('/ax/merchant/' + this.merchantId)
        .then(response => {
          this.mx = response.data
          this.$emit('retrieved', this.mx)
        })
        .catch(error => this.handleError(error))
    },
    confirmApiKeyRegen: function () {
      this.$bvModal.msgBoxConfirm("Are you sure you want to generate a new API Key?", {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        centered: true
      })
      .then(value => {
        if (value) {
          this.submitApiKeyRegen()
        }
      })
    },
    submitApiKeyRegen: function () {
      console.log('calling regen')
      this.axios.post('/ax/merchant/' + this.merchantId + '/generateapikey')
        .then(response => {
          this.mx.apiKey = response.data
          this.$toasted.success('API Key updated')
        })
        .catch(error => this.handleError(error))
    },
    profileUpdated: function () {
      this.getMerchantDetail()
      this.$bvModal.hide('merchant-form')
    }
  }
}
</script>