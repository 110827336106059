export let countriesList = [ {
  'name': 'Aland Islands',
  'code': 'AX'
}, {
  'name': 'Andorra',
  'code': 'AD'
}, {
  'name': 'Angola',
  'code': 'AO'
}, {
  'name': 'Anguilla',
  'code': 'AI'
}, {
  'name': 'Antarctica',
  'code': 'AQ'
}, {
  'name': 'Antigua and Barbuda',
  'code': 'AG'
}, {
  'name': 'Aruba',
  'code': 'AW'
}, {
  'name': 'Australia',
  'code': 'AU'
}, {
  'name': 'Austria',
  'code': 'AT'
}, {
  'name': 'Bahamas',
  'code': 'BS'
}, {
  'name': 'Bahrain',
  'code': 'BH'
}, {
  'name': 'Bangladesh',
  'code': 'BD'
}, {
  'name': 'Barbados',
  'code': 'BB'
}, {
  'name': 'Belgium',
  'code': 'BE'
}, {
  'name': 'Belize',
  'code': 'BZ'
}, {
  'name': 'Benin',
  'code': 'BJ'
}, {
  'name': 'Bermuda',
  'code': 'BM'
}, {
  'name': 'Bonaire, Sint Eustatius and Saba',
  'code': 'BQ'
}, {
  'name': 'Bosnia and Herzegovina',
  'code': 'BA'
}, {
  'name': 'Botswana',
  'code': 'BW'
}, {
  'name': 'Brazil',
  'code': 'BR'
}, {
  'name': 'British Indian Ocean Territory',
  'code': 'IO'
}, {
  'name': 'Brunei Darussalam',
  'code': 'BN'
}, {
  'name': 'Bulgaria',
  'code': 'BG'
}, {
  'name': 'Burkina Faso',
  'code': 'BF'
}, {
  'name': 'Burundi',
  'code': 'BI'
}, {
  'name': 'Canada',
  'code': 'CA'
}, {
  'name': 'Cape Verde',
  'code': 'CV'
}, {
  'name': 'Cayman Islands',
  'code': 'KY'
}, {
  'name': 'Central African Republic',
  'code': 'CF'
}, {
  'name': 'Chad',
  'code': 'TD'
}, {
  'name': 'Chile',
  'code': 'CL'
}, {
  'name': 'China',
  'code': 'CN'
}, {
  'name': 'Christmas Island',
  'code': 'CX'
}, {
  'name': 'Comoros',
  'code': 'KM'
}, {
  'name': 'Congo',
  'code': 'CG'
}, {
  'name': 'Costa Rica',
  'code': 'CR'
}, {
  'name': 'Croatia',
  'code': 'HR'
}, {
  'name': 'Curaçao',
  'code': 'CW'
}, {
  'name': 'Cyprus',
  'code': 'CY'
}, {
  'name': 'Czech Republic',
  'code': 'CZ'
}, {
  'name': 'Denmark',
  'code': 'DK'
}, {
  'name': 'Djibouti',
  'code': 'DJ'
}, {
  'name': 'Dominica',
  'code': 'DM'
}, {
  'name': 'Dominican Republic',
  'code': 'DO'
}, {
  'name': 'El Salvador',
  'code': 'SV'
}, {
  'name': 'Equatorial Guinea',
  'code': 'GQ'
}, {
  'name': 'Estonia',
  'code': 'EE'
}, {
  'name': 'Falkland Islands (Malvinas)',
  'code': 'FK'
}, {
  'name': 'Faroe Islands',
  'code': 'FO'
}, {
  'name': 'Fiji',
  'code': 'FJ'
}, {
  'name': 'Finland',
  'code': 'FI'
}, {
  'name': 'France',
  'code': 'FR'
}, {
  'name': 'French Guiana',
  'code': 'GF'
}, {
  'name': 'French Polynesia',
  'code': 'PF'
}, {
  'name': 'French Southern Territories',
  'code': 'TF'
}, {
  'name': 'Gabon',
  'code': 'GA'
}, {
  'name': 'Gambia',
  'code': 'GM'
}, {
  'name': 'Georgia',
  'code': 'GE'
}, {
  'name': 'Germany',
  'code': 'DE'
}, {
  'name': 'Gibraltar',
  'code': 'GI'
}, {
  'name': 'Greenland',
  'code': 'GL'
}, {
  'name': 'Grenada',
  'code': 'GD'
}, {
  'name': 'Guadeloupe',
  'code': 'GP'
}, {
  'name': 'Guatemala',
  'code': 'GT'
}, {
  'name': 'Guernsey',
  'code': 'GG'
}, {
  'name': 'Guinea-Bissau',
  'code': 'GW'
}, {
  'name': 'Guyana',
  'code': 'GY'
}, {
  'name': 'Haiti',
  'code': 'HT'
}, {
  'name': 'Holy See (Vatican City State)',
  'code': 'VA'
}, {
  'name': 'Honduras',
  'code': 'HN'
}, {
  'name': 'Hong Kong',
  'code': 'HK'
}, {
  'name': 'Hungary',
  'code': 'HU'
}, {
  'name': 'Iceland',
  'code': 'IS'
}, {
  'name': 'Ireland',
  'code': 'IE'
}, {
  'name': 'Isle of Man',
  'code': 'IM'
}, {
  'name': 'Israel',
  'code': 'IL'
}, {
  'name': 'Italy',
  'code': 'IT'
}, {
  'name': 'Jamaica',
  'code': 'JM'
}, {
  'name': 'Japan',
  'code': 'JP'
}, {
  'name': 'Jersey',
  'code': 'JE'
}, {
  'name': 'Jordan',
  'code': 'JO'
}, {
  'name': 'Kiribati',
  'code': 'KI'
}, {
  'name': 'Korea, Republic of',
  'code': 'KR'
}, {
  'name': 'Kuwait',
  'code': 'KW'
}, {
  'name': 'Lao People\'s Democratic Republic',
  'code': 'LA'
}, {
  'name': 'Latvia',
  'code': 'LV'
}, {
  'name': 'Lesotho',
  'code': 'LS'
}, {
  'name': 'Liechtenstein',
  'code': 'LI'
}, {
  'name': 'Lithuania',
  'code': 'LT'
}, {
  'name': 'Luxembourg',
  'code': 'LU'
}, {
  'name': 'Macao',
  'code': 'MO'
}, {
  'name': 'Macedonia, the Former Yugoslav Republic of',
  'code': 'MK'
}, {
  'name': 'Madagascar',
  'code': 'MG'
}, {
  'name': 'Malawi',
  'code': 'MW'
}, {
  'name': 'Malaysia',
  'code': 'MY'
}, {
  'name': 'Maldives',
  'code': 'MV'
}, {
  'name': 'Mali',
  'code': 'ML'
}, {
  'name': 'Malta',
  'code': 'MT'
}, {
  'name': 'Martinique',
  'code': 'MQ'
}, {
  'name': 'Mauritania',
  'code': 'MR'
}, {
  'name': 'Mauritius',
  'code': 'MU'
}, {
  'name': 'Mayotte',
  'code': 'YT'
}, {
  'name': 'Monaco',
  'code': 'MC'
}, {
  'name': 'Montenegro',
  'code': 'ME'
}, {
  'name': 'Montserrat',
  'code': 'MS'
}, {
  'name': 'Morocco',
  'code': 'MA'
}, {
  'name': 'Namibia',
  'code': 'NA'
}, {
  'name': 'Nepal',
  'code': 'NP'
}, {
  'name': 'Netherlands',
  'code': 'NL'
}, {
  'name': 'New Caledonia',
  'code': 'NC'
}, {
  'name': 'New Zealand',
  'code': 'NZ'
}, {
  'name': 'Nicaragua',
  'code': 'NI'
}, {
  'name': 'Niger',
  'code': 'NE'
}, {
  'name': 'Niue',
  'code': 'NU'
}, {
  'name': 'Norfolk Island',
  'code': 'NF'
}, {
  'name': 'Norway',
  'code': 'NO'
}, {
  'name': 'Oman',
  'code': 'OM'
}, {
  'name': 'Palestine, State of',
  'code': 'PS'
}, {
  'name': 'Panama',
  'code': 'PA'
}, {
  'name': 'Papua New Guinea',
  'code': 'PG'
}, {
  'name': 'Paraguay',
  'code': 'PY'
}, {
  'name': 'Philippines',
  'code': 'PH'
}, {
  'name': 'Pitcairn',
  'code': 'PN'
}, {
  'name': 'Poland',
  'code': 'PL'
}, {
  'name': 'Portugal',
  'code': 'PT'
}, {
  'name': 'Qatar',
  'code': 'QA'
}, {
  'name': 'Réunion',
  'code': 'RE'
}, {
  'name': 'Romania',
  'code': 'RO'
}, {
  'name': 'Rwanda',
  'code': 'RW'
}, {
  'name': 'Saint Barthélemy',
  'code': 'BL'
}, {
  'name': 'Saint Helena, Ascension and Tristan da Cunha',
  'code': 'SH'
}, {
  'name': 'Saint Kitts and Nevis',
  'code': 'KN'
}, {
  'name': 'Saint Lucia',
  'code': 'LC'
}, {
  'name': 'Saint Martin (French part)',
  'code': 'MF'
}, {
  'name': 'Saint Pierre and Miquelon',
  'code': 'PM'
}, {
  'name': 'Saint Vincent and the Grenadines',
  'code': 'VC'
}, {
  'name': 'Samoa',
  'code': 'WS'
}, {
  'name': 'San Marino',
  'code': 'SM'
}, {
  'name': 'Saudi Arabia',
  'code': 'SA'
}, {
  'name': 'Senegal',
  'code': 'SN'
}, {
  'name': 'Serbia',
  'code': 'RS'
}, {
  'name': 'Seychelles',
  'code': 'SC'
}, {
  'name': 'Sierra Leone',
  'code': 'SL'
}, {
  'name': 'Singapore',
  'code': 'SG'
}, {
  'name': 'Sint Maarten (Dutch part)',
  'code': 'SX'
}, {
  'name': 'Slovakia',
  'code': 'SK'
}, {
  'name': 'Slovenia',
  'code': 'SI'
}, {
  'name': 'Solomon Islands',
  'code': 'SB'
}, {
  'name': 'South Africa',
  'code': 'ZA'
}, {
  'name': 'South Sudan',
  'code': 'SS'
}, {
  'name': 'Spain',
  'code': 'ES'
}, {
  'name': 'Sudan',
  'code': 'SD'
}, {
  'name': 'Suriname',
  'code': 'SR'
}, {
  'name': 'Svalbard and Jan Mayen',
  'code': 'SJ'
}, {
  'name': 'Swaziland',
  'code': 'SZ'
}, {
  'name': 'Sweden',
  'code': 'SE'
}, {
  'name': 'Switzerland',
  'code': 'CH'
}, {
  'name': 'Taiwan, Province of China',
  'code': 'TW'
}, {
  'name': 'Tajikistan',
  'code': 'TJ'
}, {
  'name': 'Timor-Leste',
  'code': 'TL'
}, {
  'name': 'Togo',
  'code': 'TG'
}, {
  'name': 'Tokelau',
  'code': 'TK'
}, {
  'name': 'Tonga',
  'code': 'TO'
}, {
  'name': 'Trinidad and Tobago',
  'code': 'TT'
}, {
  'name': 'Tunisia',
  'code': 'TN'
}, {
  'name': 'Turkmenistan',
  'code': 'TM'
}, {
  'name': 'Turks and Caicos Islands',
  'code': 'TC'
}, {
  'name': 'Tuvalu',
  'code': 'TV'
}, {
  'name': 'Uganda',
  'code': 'UG'
}, {
  'name': 'Ukraine',
  'code': 'UA'
}, {
  'name': 'United Arab Emirates',
  'code': 'AE'
}, {
  'name': 'United Kingdom',
  'code': 'GB'
}, {
  'name': 'United States',
  'code': 'US'
}, {
  'name': 'United States Minor Outlying Islands',
  'code': 'UM'
}, {
  'name': 'Uruguay',
  'code': 'UY'
}, {
  'name': 'Uzbekistan',
  'code': 'UZ'
}, {
  'name': 'Vanuatu',
  'code': 'VU'
}, {
  'name': 'Venezuela, Bolivarian Republic of',
  'code': 'VE'
}, {
  'name': 'Viet Nam',
  'code': 'VN'
}, {
  'name': 'Virgin Islands, British',
  'code': 'VG'
}, {
  'name': 'Virgin Islands, U.S.',
  'code': 'VI'
}, {
  'name': 'Wallis and Futuna',
  'code': 'WF'
}, {
  'name': 'Western Sahara',
  'code': 'EH'
}, {
  'name': 'Yemen',
  'code': 'YE'
}, {
  'name': 'Zambia',
  'code': 'ZM'
}, {
  'name': 'Zimbabwe',
  'code': 'ZW'
} ]
