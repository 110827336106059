<template>
<div class="container">
  <div v-if="mode === 'VIEW'">
    <div class="row">
      <div class="col-auto ms-auto small text-info"><a @click.prevent="showForm()" href="#"><i class="fas fa-edit"></i> Edit</a></div>
    </div>
    <div class="row">
      <display-field label="Channel Code" group-class="col-auto">{{ch.channelCode}}</display-field>
    </div>
    <div class="row">
      <display-field label="Currency" group-class="col-6">{{ch.currency}}</display-field>
      <display-field label="Processor ID" group-class="col-6">{{ch.procId}}</display-field>
    </div>
    <div class="row">
      <display-field label="Descripton" group-class="col-12">{{ch.description}}</display-field>
    </div>
    
    <div class="row text-white border py-2" :class="[ch.payinSpecs.enabled ? 'bg-success' : 'bg-warning']">
      <div class="col-auto small fst-italic">Pay-In Specifications</div>
      <div v-if="ch.payinSpecs.enabled" class="col-auto ms-auto fw-bold">ENABLED</div>
      <div v-else class="col-auto ms-auto fw-bold">DISABLED</div>
    </div>
    <div v-if="ch.payinSpecs.enabled" class="row border py-2">
      <div class="row">
        <display-field label="Transaction Fee" group-class="col-12">
          <span v-if="ch.payinFeeRule">{{ch.payinFeeRule.feeDescription}}</span>
          <span v-else>Zero Fees</span>
        </display-field>
      </div>
      <div class="row">
        <display-field label="Minimum Amount" group-class="col-auto">
          <display-amount :value="ch.payinSpecs.minTxnAmount"/>
        </display-field>
        <display-field label="Maximum Amount" group-class="col-auto">
          <display-amount :value="ch.payinSpecs.maxTxnAmount"/>
        </display-field>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <p class="mb-1 small text-info">Required "endUserInfo" Fields</p>
          <p v-if="!ch.payinSpecs.requiredEndUserInfo || ch.payinSpecs.requiredEndUserInfo.length === 0" class="ps-3 small fw-bold">--NONE--</p>
          <ul class="list-group">
            <li v-for="(piFld, idx) in ch.payinSpecs.requiredEndUserInfo" :key="idx" class="list-group-item">
              {{piFld.fieldName}} - 
              <span class="small text-warning fst-italic">{{piFld.description}}</span>
            </li>
          </ul>
        </div>
        <div class="col-6">
          <p class="mb-1 small text-info">Required "payInfo" Fields</p>
          <p v-if="!ch.payinSpecs.requiredPayInfo || ch.payinSpecs.requiredPayInfo.length === 0" class="ps-3 small fw-bold">--NONE--</p>
          <ul class="list-group">
            <li v-for="(piFld, idx) in ch.payinSpecs.requiredPayInfo" :key="idx" class="list-group-item">
              {{piFld.fieldName}} - 
              <span class="small text-warning fst-italic">{{piFld.description}}</span>
              <template v-if="piFld.fieldName === 'bankCode'">
                <span v-if="ch.payinSpecs.validateBankCode" class="text-danger small fst-italic"> (Validated)</span>
                <span v-else class="text-danger small fst-italic"> (Passthrough)</span>
              </template>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="ch.payinSpecs.bankCodeRequired && ch.payinSpecs.validateBankCode" class="col-12">
        <a href="#" @click.prevent="showBanks()" class="text-info text-decoration-none small"><i class="fa-solid fa-building-columns me-2"></i>Available Banks</a>
      </div>
    </div>
    
    <div class="row text-white border mt-3 py-2" :class="[ch.payoutSpecs.enabled ? 'bg-success' : 'bg-warning']">
      <div class="col-auto small fst-italic">Pay-Out Specifications</div>
      <div v-if="ch.payoutSpecs.enabled" class="col-auto ms-auto fw-bold">ENABLED</div>
      <div v-else class="col-auto ms-auto fw-bold">DISABLED</div>
    </div>
    <div v-if="ch.payoutSpecs.enabled" class="row border py-2">
      <div class="row">
        <display-field label="Transaction Fee" group-class="col-12">
          <span v-if="ch.payoutFeeRule">{{ch.payoutFeeRule.feeDescription}}</span>
          <span v-else>Zero Fees</span>
        </display-field>
      </div>
      <div class="row">
        <display-field label="Minimum Amount" group-class="col-auto">
          <display-amount :value="ch.payoutSpecs.minTxnAmount"/>
        </display-field>
        <display-field label="Maximum Amount" group-class="col-auto">
          <display-amount :value="ch.payoutSpecs.maxTxnAmount"/>
        </display-field>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <p class="mb-1 small text-info">Required "endUserInfo" Fields</p>
          <p v-if="!ch.payoutSpecs.requiredEndUserInfo || ch.payoutSpecs.requiredEndUserInfo.length === 0" class="ps-3 small fw-bold">--NONE--</p>
          <ul class="list-group">
            <li v-for="(piFld, idx) in ch.payoutSpecs.requiredEndUserInfo" :key="idx" class="list-group-item">
              {{piFld.fieldName}} - 
              <span class="small text-warning fst-italic">{{piFld.description}}</span>
            </li>
          </ul>
        </div>
        <div class="col-6">
          <p class="mb-1 small text-info">Required "payInfo" Fields</p>
          <p v-if="!ch.payoutSpecs.requiredPayInfo || ch.payoutSpecs.requiredPayInfo.length === 0" class="ps-3 small fw-bold">--NONE--</p>
          <ul class="list-group">
            <li v-for="(piFld, idx) in ch.payoutSpecs.requiredPayInfo" :key="idx" class="list-group-item">
              {{piFld.fieldName}} - 
              <span class="small text-warning fst-italic">{{piFld.description}}</span>
              <template v-if="piFld.fieldName === 'bankCode'">
                <span v-if="ch.payoutSpecs.validateBankCode" class="text-danger small fst-italic"> (Validated)</span>
                <span v-else class="text-danger small fst-italic"> (Passthrough)</span>
              </template>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="ch.payoutSpecs.bankCodeRequired && ch.payoutSpecs.validateBankCode" class="col-12">
        <a href="#" @click.prevent="showBanks()" class="text-info text-decoration-none small"><i class="fa-solid fa-building-columns me-2"></i>Available Banks</a>
      </div>
    </div>
    
    <template v-if="ch.staticProcParams && Object.keys(ch.staticProcParams).length > 0">
    <div class="row text-white border mt-3 py-2 bg-secondary">
      <div class="col-auto small fst-italic">Channel-Specific Processor Parameters</div>
    </div>
    <div class="row border py-2">
      <div class="col-auto">
        <ul>
          <li v-for="(objKey, idx) in Object.keys(ch.staticProcParams)" :key="idx">{{(objKey)}} : {{ch.staticProcParams[objKey]}}</li>
        </ul>
      </div>
    </div>
    </template>
  </div>
  
  
  <div v-if="mode === 'EDIT'">
    <payment-channel-form :action="'EDIT'" :channelCode="channelCode"
      @saved="onFormSaved" @cancelled="mode = 'VIEW'">
    </payment-channel-form>
  </div>

  <b-modal id="ch-banks" title="Available Banks" hide-footer size="lg">
    <payment-channel-banks :channelCode="channelCode"></payment-channel-banks>
  </b-modal>

</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import PaymentChannelForm from '../components/PaymentChannelForm.vue'
import PaymentChannelBanks from '../components/PaymentChannelBanks.vue'
export default {
  name: 'PaymentChannelDetails',
  mixins: [HelpersMixin],
  props: ['channelCode'],
  components: { PaymentChannelForm, PaymentChannelBanks },
  data () {
    return {
      ch: { payinSpecs: {}, payoutSpecs: {} },
      mode: 'VIEW'
    }
  },
  mounted () {
    this.getChannel()
  },
  methods: {
    getChannel: function () {
      this.axios.get('/ax/paymentchannel/' + this.channelCode)
      .then( response => {
        this.ch = response.data
      })
      .catch(error => this.handleError(error))
    },
    showForm: function () {
      this.mode = 'EDIT'
    },
    onFormSaved: function () {
      this.getChannel()
      this.mode = 'VIEW'
    },
    showBanks: function () {
      this.$bvModal.show('ch-banks')
    }
    
  }
}
</script>