<template>
<div class="container">

  <div class="row">
    <div v-if="banksList && banksList.length > 0" class="col">
      <table class="table table-sm table-striped">
        <thead class="table-dark">
          <tr>
            <th>System Bank Code</th>
            <th>Bank Name</th>
            <th>Processor Bank Code</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bank in banksList" :key="bank.bankCode">
            <td>{{bank.sysBankCode}}</td>
            <td>{{bank.bankName}}</td>
            <td>{{bank.procBankCode}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="col-auto mx-auto fw-bold text-danger py-3 fs-3">
      <span v-if="isLoading"><b-spinner variant="primary" label="Please wait"></b-spinner></span>
      <span v-else>No Banks Available</span>
    </div>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'PaymentChannelBanks',
  mixins: [HelpersMixin],
  props: [ 'channelCode' ],
  data () {
    return {
      banksList: [],
      isLoading: true
    }
  },
  mounted () {
    this.getBanks()
  },
  methods: {
    getBanks: function () {
      this.axios.get('/ax/paymentchannelbanks/' + this.channelCode)
      .then(result => {
        this.banksList = result.data
        this.isLoading = false
      })
      .catch(error => this.handleError(error))
    }
  }
}
</script>