<template>
<div class="container">
  <page-title title="Change Password"/>
  
  <div class="row" v-if="changeSuccess">
    <div class="col-auto mx-auto alert alert-success">Your password has been successfully changed</div>
  </div>
  
  <div class="row" v-else>
    <div class="col-md-5 mx-auto border py-3 px-4">
      <div class="alert alert-danger" v-if="error.hasError">{{error.errorMsg}}</div>
      <form v-on:submit.prevent="onSubmit" action="">
        <input-text v-model="currentPw" masked label="Current Password"></input-text>
        <input-text v-model="newPw1" masked label="New Password"></input-text>
        <input-text v-model="newPw2" masked label="Retype New Password"></input-text>
        <div class="text-end mt-3">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>  
    </div>
  </div>
  
</div>
</template>

<script>
export default {
  name: 'AdminPwChange',
  data () {
    return {
      currentPw: '',
      newPw1: '',
      newPw2: '',
      error: { hasError: false, errorMsg: '' },
      changeSuccess: false
    }
  },
  methods: {
    onSubmit: function () {
      this.error.hasError = false
      this.error.errorMsg = ''
      var inputData = { currentPw: this.currentPw, newPw1: this.newPw1, newPw2: this.newPw2 }
      this.axios.post('/ax/submitpwchange', inputData)
        .then(() => {
          this.currentPw = ''
          this.newPw1 = ''
          this.newPw2 = ''
          this.changeSuccess = true
        })
        .catch(error => {
          console.log(error.response.status + ': ' + error.response.data)
          this.error.hasError = true
          this.error.errorMsg = error.response.data.msgText
        })
    }
  }
}
</script>
