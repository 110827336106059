<template>
<div class="container">
  <page-title title="Processor Details"/>

  <div class="card">
    <div class="card-body">
      <div class="row">
        <display-field label="Processor ID" v-if="mode === 'update'" group-class="col-6">{{formData.procId}}</display-field>
        <input-text v-else label="Processor ID" v-model="formData.procId" group-class="col-6"
          :errors="errors" error-key="procId"></input-text>
        <div class="col-6">
          <div class="form-check ms-3 pt-4">
            <input type="checkbox" class="form-check-input" v-model="formData.active"/>
            <label class="form-check-label" style="font-weight: 600;">Is Active</label>
          </div>
        </div>
        <div class="col-12 my-3">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="formData.implType" value="LAMBDA"/>
            <label class="form-check-label">AWS Lambda</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="formData.implType" value="INLINE"/>
            <label class="form-check-label">Inline</label>
          </div>
        </div>
        <template v-if="formData.implType === 'INLINE'">
          <input-text label="Implementation Class" v-model="formData.implSpec" group-class="col-12"
            :errors="errors" error-key="implSpec"></input-text>
        </template>
        <template v-else>
          <input-text label="Lambda Function Name" v-model="formData.implSpec" group-class="col-12"
            :errors="errors" error-key="implSpec"></input-text>
        </template>
        <input-text label="Description" v-model="formData.desc" group-class="col-12"
          :errors="errors" error-key="desc"></input-text>
      </div>
   </div>
  </div>

  <h6 class="text-left mt-4">Proc Attributes</h6>
  <div class="mt-2 border px-1">
    <table class="table table-sm">
      <thead class="thead-dark small">
        <tr>
          <th width="30%">Attribute Name</th>
          <th width="65%">Attribute Value</th>
          <th width="5%">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(attr, index) in attribs" v-bind:key="index">
          <td><input class="form-control form-control-sm" type="text" v-model="attr.name"/></td>
          <td><input class="form-control form-control-sm" type="text" v-model="attr.value"/></td>
          <td class="text-center"><a href="#"><i class="fas fa-trash-alt" v-on:click="onDelAttrib(index)"></i></a></td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
          <td class="text-center"><a href="#"><i class="fas fa-plus" v-on:click="onAddAttrib()"></i></a></td>
        </tr>
      </tbody>
    </table>
  </div>
 
   <h6 class="text-left mt-4">Result Code Mapping Rules</h6>
  <div class="mt-2 border px-1">
    <table class="table table-sm">
      <thead class="thead-dark small">
      <tr>
        <th width="30%">Attribute Name</th>
        <th width="10%">&nbsp;</th>
        <th width="30%">Condition Value</th>
        <th width="25">Txn Result Code</th>
        <th width="5%">&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(rule, index) in resultCodeMappingRules" :key="index">
        <td><input class="form-control form-control-sm" type="text" v-model="rule.attrName"/></td>
        <td class="text-center">contains</td>
        <td><input class="form-control form-control-sm" type="text" v-model="rule.condValue"/></td>
        <td>
          <select class="form-control form-control-sm" v-model="rule.txnResultCode">
            <option v-for="(trc, index) in txnResultCodes" :key="index" :value="trc">{{trc}}</option>
          </select>
        </td>
        <td class="text-center"><a href="#" @click.prevent="onDelRule(index)"><i class="fas fa-trash-alt"></i></a></td>
      </tr>
      <tr>
        <td colspan="4">&nbsp;</td>
        <td class="text-center"><a href="#" @click.prevent="onAddRule()"><i class="fas fa-plus"></i></a></td>
      </tr>
      </tbody>
    </table>
  </div>
 
  <div class="text-end py-3">
    <button class="btn btn-secondary me-2" v-on:click="onCancel()">Cancel</button>
    <button class="btn btn-primary" v-on:click="onSave()">Save</button>
  </div>

</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'ProcForm',
  mixins: [HelpersMixin],
  props: ['proc'],
  data () {
    return {
      implTypeOptions: [ {value: 'LAMBDA', text: ' AWS Lambda'}, {value: 'INLINE', text: ' Inline'} ],
      formData: { procType: 'BDPROC', implType: 'LAMBDA'},
      attribs: [],
      resultCodeMappingRules: [],
      txnResultCodes: [],
      mode: 'create'
    }
  },
  mounted () {
    this.getTxnResultCodes()
    if (this.proc) {
      this.mode = 'update'
      Object.assign(this.formData, this.proc)
      this.resultCodeMappingRules = this.formData.resultCodeMappingRules
      this.propsToAttribs(this.proc.attribs)
    } else {
      this.mode = 'create'
    }
  },
  methods: {
    onAddAttrib: function () {
      this.attribs.push({ name: '', value: '' })
    },
    onDelAttrib: function (idx) {
      this.attribs.splice(idx, 1)
    },
    onAddRule: function () {
      this.resultCodeMappingRules.push({ attrName: '', condValue: '', txnResultCode: '' })
    },
    onDelRule: function (idx) {
      this.resultCodeMappingRules.splice(idx, 1)
    },
    onSave: function () {
      this.$forceUpdate()
      this.attribsToMap()
      Object.assign(this.resultCodeMappingRules, this.formData.resultCodeMappingRules)
      this.formData.resultCodeMappingRules = this.resultCodeMappingRules

      this.errors.clearAll()
      if (this.mode === 'create') {
        this.axios.put('/ax/procattribs', this.formData)
        .then(() => {
          this.$emit('saved')
        })
        .catch(error => this.handleValidationErrors(error))
      } else {
        this.axios.post('/ax/procattribs', this.formData)
        .then(() => {
          this.$emit('saved')
        })
        .catch(error => this.handleValidationErrors(error))
      }
    },
    onCancel: function () {
      this.$emit('cancelled')
    },
    propsToAttribs: function (props) {
      for (var key in props) {
        this.attribs.push({ name: key, value: props[key] })
      }
    },
    attribsToMap: function () {
      var attribMap = {}
      for (var i = 0; i < this.attribs.length; i++) {
        attribMap[this.attribs[i].name] = this.attribs[i].value
      }
      this.formData.attribs = attribMap
    },
    getTxnResultCodes: function () {
      this.axios.get('/ax/txnresultcodes')
      .then (response => {
        this.txnResultCodes = response.data
      })
      .catch(error => this.handleError(error))
    }
    
  }
}
</script>