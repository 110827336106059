<template>
<span>
  <span v-if="$store.state.mxLookup[merchantId]">{{$store.state.mxLookup[merchantId].name}}
    <small class="text-secondary" v-if="!hideId"><br/>{{merchantId}}</small>
  </span>
  <span v-else>{{merchantId}}</span>
</span>
</template>

<script>
export default {
  name: 'MerchantLookup',
  props: { 
    merchantId: String, 
    hideId: { type: Boolean, default: false } 
    }
}
</script>