<template>
<div class="row">
  <div class="col-12 small text-info">
    <span v-if="axu.lastLogin">
    Last logged-in on <display-date :value="axu.lastLogin"/>
    </span>
    <span v-if="axu.locked" class="text-danger font-weight-bold ms-3">LOCKED-OUT</span>
  </div>

  <div class="col-12 text-end" v-if="mode === 'update' && formDisabled">
    <a href="#" @click="onPwReset" v-b-tooltip.hover title="Reset Password" class="me-3"><i class="fas fa-key"></i></a>
    <a href="#" @click="onEdit" v-b-tooltip.hover title="Edit"><i class="fas fa-edit"></i></a>
  </div>

  <div class="col-12">
    <form>
      <fieldset :disabled="formDisabled">
      <input-text label="User ID" v-model="axu.userId" placeholder="enter login ID"
        :errors="errors" error-key="userId"/>
      <input-text label="First Name" v-model="axu.firstName" placeholder="enter first name"
        :errors="errors" error-key="firstName"/>
      <input-text label="Last Name" v-model="axu.lastName" placeholder="enter last name"
        :errors="errors" error-key="lastName"/>
      <input-text label="Email Address" v-model="axu.emailAddress" placeholder="enter email address"
        :errors="errors" error-key="emailAddress"/>
      </fieldset>
    </form>
    <div class="text-end mt-5 mb-3" v-if="!formDisabled">
      <button class="btn btn-secondary me-2" @click="onCancel()">Cancel</button>
      <button class="btn btn-primary" @click="onSave()">Save</button>
    </div>
  </div>

</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'AdminUserForm',
  mixins: [HelpersMixin],
  props: {
    userId: String
  },
  data () {
    return {
      axu: {},
      mode: 'add',
      formDisabled: true
    }
  },
  mounted () {
    if (this.userId) {
      this.mode = 'update'
      this.getAdminUser()
    } else {
      this.mode = 'add'
      this.formDisabled = false
    }
  },
  methods: {
    getAdminUser: function () {
      this.axios.get('/ax/adminuser/' + this.userId)
      .then(response => {
        this.axu = response.data
      })
      .catch(error => {
        this.handleError(error)
      })
    },
    onSave: function () {
      this.$forceUpdate()
      this.errors.clearAll()
      if (this.mode === 'add') {
        this.axios.put('/ax/adminuser', this.axu)
        .then(() => {
          this.$emit('saved')
        })
        .catch(error => this.handleValidationErrors(error))
      }
      else {
        this.axios.post('/ax/adminuser', this.mxu)
        .then(() => {
          this.$emit('saved')
        })
        .catch(error => this.handleValidationErrors(error))
      }
    },
    onCancel: function () {
      this.$emit('cancelled')
    },
    onEdit: function () {
      this.formDisabled = false
    },
    onPwReset: function () {
      this.$bvModal.msgBoxConfirm("Are you sure you want to reset this user's password?", {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        centered: true
      })
      .then(value => {
        console.log('confirm value: ' + value)
        if (value) {
          this.submitPwReset()
        }
      })
    },
    submitPwReset: function () {
      console.log('submitting pw reset')
      this.axios.post('/ax/adminuser/reset/' + this.userId)
      .then(() => {
        this.$toasted.success('A new password has been generated and sent to ' + this.axu.emailAddress)
        this.$emit('saved')
      })
      .catch(error => {
        this.handleError(error)
        this.formDisabled = false
      })
    }
  }
}
</script>