<template>
<div class="container">
  <div class="row mb-3">
    <display-field group-class="col-auto" label="Account ID">{{mxAccount.accountId}}</display-field>
    <template v-if="editName">
      <input-text v-model="accountNameInput" label="Account Name" group-class="col-auto"></input-text>
      <a href="#" class="col-auto text-danger fs-5 mt-4" @click.prevent="editName=false"><i class="fa-solid fa-xmark"></i></a>
      <a href="#" class="col-auto text-success fs-5 mt-4" @click.prevent="updateName()"><i class="fa-solid fa-check"></i></a>
    </template>
    <display-field v-else group-class="col-auto" label="Account Name">
      {{mxAccount.accountName}}
      <a @click.prevent="showEditName()" href="#"><i class="fas fa-edit ms-2"></i></a>
    </display-field>
    <div class="col-auto ms-auto form-check form-switch mt-3">
      <input v-model="isActive" class="form-check-input" type="checkbox" id="accountStatus">
      <label class="form-check-label" for="accountStatus">
        <span v-if="isActive" class="text-success font-weight-bold ps-2">ACTIVE</span>
        <span v-else class="text-danger font-weight-bold ps-2">INACTIVE</span>
      </label>
    </div>
  </div>
  
  <div class="row">
    <div class="col-12">
      <table class="table table-sm table-bordered">
        <thead class="table-dark">
          <tr>
            <th width="20%">Channel Code</th>
            <th width="35%">Pay-In Transaction Fee</th>
            <th width="35%">Pay-Out Transaction Fee</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="fr in feeRules" :key="fr.channelCode">
            <td>{{fr.channelCode}}</td>
            <td>
              <span v-if="!fr.payinEnabled" class="text-warning fst-italic">Pay-In Disabled</span>
              <template v-else-if="fr.payinFeeRule">
                <span>{{fr.payinFeeRule.feeDescription}}</span>
                <span v-if="fr.payinSourceLevel === 2" class="text-danger fst-italic small"><br/>Account Override</span>
              </template>
              <span v-else>No Fees</span>
            </td>
            <td>
              <span v-if="!fr.payoutEnabled" class="text-warning fst-italic">Pay-Out Disabled</span>
              <template v-else-if="fr.payoutFeeRule">
                <span>{{fr.payoutFeeRule.feeDescription}}</span>
                <span v-if="fr.payoutSourceLevel === 2" class="text-danger fst-italic small"><br/>Account Override</span>
              </template>
              <span v-else>No Fees</span>
            </td>
            <td class="text-center"><a @click.prevent="showFeeRuleForm(fr)" href="#"><i class="fas fa-edit"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <b-modal id="feerule-form" title="Account FeeRules" hide-footer>
    <account-fee-rule-form :merchant-id="merchantId" :account-id="accountId" :currentFeeRules="selectedFeeRules"
      @cancelled="$bvModal.hide('feerule-form')"
      @saved="onFeeRuleFormSaved">
    </account-fee-rule-form>
  </b-modal>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import AccountFeeRuleForm from '../components/AccountFeeRuleForm.vue'
export default {
    name: 'MerchantAccountDetails',
    mixins: [HelpersMixin],
    props: ['merchantId', 'accountId'],
    components: {AccountFeeRuleForm},
    data () {
        return {
            mxAccount: {},
            feeRules: [],
            selectedFeeRules: {},
            accountNameInput: '',
            editName: false,
            isActive: false,
            statusChangeCancelled: false
        }
    },
    mounted () {
        this.getAccount()
        this.getFeeRules()
    },
    methods: {
        getAccount: function () {
            this.axios.get('/ax/mxaccount/' + this.merchantId + '/' + this.accountId)
            .then (response => {
                this.mxAccount = response.data
                this.isActive = this.mxAccount.active
                this.$watch('isActive', this.onStatusChange)
            })
            .catch(error => this.handleError(error))
        },
        getFeeRules: function () {
            this.axios.get('/ax/accountfeerules/' + this.merchantId + '/' + this.accountId)
            .then(response => {
                this.feeRules = response.data
            })
            .catch(error => this.handleError(error))
        },
        showFeeRuleForm: function (feeRules) {
            this.selectedFeeRules = feeRules
            this.$bvModal.show('feerule-form')
        },
        onFeeRuleFormSaved: function () {
            this.getFeeRules()
            this.$bvModal.hide('feerule-form')
        },
        showEditName: function () {
            this.accountNameInput = this.mxAccount.accountName
            this.editName = true
        },
        updateName: function () {
            const payload = {merchantId: this.merchantId, accountId: this.accountId, accountName: this.accountNameInput}
            this.axios.post('/ax/mxaccountname', payload)
            .then( () => {
                this.mxAccount.accountName = this.accountNameInput
                this.editName = false
                this.$emit('saved')
            })
            .catch(error => this.handleError(error))
        },
        onStatusChange: function () {
            if (this.statusChangeCancelled) {
                this.statusChangeCancelled = false
                return
            }
            var confirmMsg = ''
            if (this.isActive) {
                confirmMsg = 'Activate Account ?'
            } else {
                confirmMsg = 'Deactivate Account ?'
            }
            this.$bvModal.msgBoxConfirm(' ', {
                title: confirmMsg,
                size: 'sm',
                buttonSize: 'sm',
                centered: true
              })
              .then(value => {
                console.log('confirm value: ' + value)
                if (value) {
                    this.updateStatus()
                } else {
                    this.statusChangeCancelled = true
                    this.isActive = !this.isActive
                }
              })
        },
        updateStatus: function () {
            const payload = {merchantId: this.merchantId, accountId: this.accountId, status: this.isActive}
            this.axios.post('/ax/mxaccountstatus', payload)
            .then( () => {
                this.mxAccount.active = this.isActive
                this.$emit('saved')
            })
            .catch(error => this.handleError(error))
        }
    }
}
</script>