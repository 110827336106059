<template>
  <div class="row">
    <input-select v-model="periodOpt" group-class="col-auto me-0 pe-0" input-class="text-white bg-secondary fw-bold text-center">
      <option value="TODAY">Today</option>
      <option value="THISWEEK">This Week</option>
      <option value="LASTWEEK">Last Week</option>
      <option value="LAST7DAYS">Last 7 Days</option>
      <option value="THISMONTH">This Month</option>
      <option value="LASTMONTH">Last Month</option>
      <option value="DATERANGE">Date Range</option>
    </input-select>
    <template v-if="periodOpt === 'DATERANGE'">
      <input-date v-model="startDate" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
      <div class="col-auto mt-2 mx-0 px-0">to</div>
      <input-date v-model="endDate" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
      <div class="col-auto">
        <button class="btn btn-primary btn-sm ms-1 pb-2" @click.prevent="broadcastEvent()">Go</button>
      </div>
    </template>
    <template v-else>
      <div class="col-auto pt-1 text-primary border border-secondary ms-0">
        <span class="fw-bold fs-6">{{startDate}}</span>
        <span v-if="periodOpt !== 'TODAY'" class="small px-2">to</span>
        <span v-if="periodOpt !== 'TODAY'" class="fw-bold fs-6">{{endDate}}</span>
      </div>
    </template>
  </div>

</template>

<script>
import moment from 'moment'
export default {
  name: 'PeriodSelector',
  props: {
    defaultPeriod: String
  },
  data () {
    return {
      periodOpt: '',
      period: {},
      startDate: '',
      endDate: ''
    }
  },
  mounted () {
    if (this.defaultPeriod) {
      this.periodOpt = this.defaultPeriod
    } else {
      this.periodOpt = 'TODAY'
    }
    this.calcPeriod()
    this.$watch('periodOpt', this.calcPeriod)
  },
  methods: {
    calcPeriod: function () {
      var mnow = moment().utc()
      if (this.periodOpt === 'TODAY') {
        this.startDate = mnow.format('YYYY-MM-DD')
        this.endDate = mnow.format('YYYY-MM-DD')
      } else if (this.periodOpt === 'THISWEEK') {
        this.startDate = mnow.startOf('isoweek').format('YYYY-MM-DD')
        this.endDate = mnow.endOf('isoweek').format('YYYY-MM-DD')
      } else if (this.periodOpt === 'LASTWEEK') {
        mnow = mnow.subtract(7, 'days')
        this.startDate = mnow.startOf('isoweek').format('YYYY-MM-DD')
        this.endDate = mnow.endOf('isoweek').format('YYYY-MM-DD')
      } else if (this.periodOpt === 'LAST7DAYS') {
        this.endDate = mnow.format('YYYY-MM-DD')
        var weekAgo = mnow.subtract(7, 'days')
        this.startDate = weekAgo.format('YYYY-MM-DD')
      } else if (this.periodOpt === 'THISMONTH') {
        this.startDate = mnow.startOf('month').format('YYYY-MM-DD')
        this.endDate = mnow.endOf('month').format('YYYY-MM-DD')
      } else if (this.periodOpt === 'LASTMONTH') {
        mnow = mnow.subtract(1, 'months')
        this.startDate = mnow.startOf('month').format('YYYY-MM-DD')
        this.endDate = mnow.endOf('month').format('YYYY-MM-DD')
      } else {
        this.periodOpt = 'DATERANGE'
        return
      }
      this.broadcastEvent()    
    },
    broadcastEvent: function () {
      this.period = {periodOpt: this.periodOpt, startDate: this.startDate, endDate: this.endDate}
      this.$emit('periodUpdated', this.period)
    }
  }
  
}
</script>
