<template>
<div class="container">
  <page-title title="Payment Channels"/>
  
  <div class="row border bg-light py-2 mb-2">
    <input-select v-model="filterCurrency" group-class="col-auto">
      <option value="ALL">All Currencies</option>
      <option v-for="cur in currencies" :key="cur.refCode" :value="cur.refCode">{{cur.refCode}} - {{cur.refValue}}</option>
    </input-select>
    <input-select v-model="filterProc" group-class="col-auto">
      <option value="ALL">All Processors</option>
      <option v-for="proc in procIds" :key="proc" :value="proc">{{proc}}</option>
    </input-select>
  </div>
  
  <div class="row">
    <div class="col-auto ms-auto small text-info"><a @click.prevent="showForm('ADD')" href="#"><i class="fas fa-plus-square"></i> Create New</a></div>
  </div>
  
  <div class="row">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Channel Code</th>
          <th>Description</th>
          <th>Currency</th>
          <th>Pay-In</th>
          <th>Pay-Out</th>
          <th>Processor</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="ch in channels">
        <tr v-if="(filterCurrency === 'ALL' || ch.currency === filterCurrency) && (filterProc === 'ALL' || ch.procId === filterProc)" :key="ch.channelCode">
          <td><a href="#" @click.prevent="showDetails(ch.channelCode)"> {{ch.channelCode}}</a></td>
          <td>{{ch.description}}</td>
          <td>{{ch.currency}}</td>
          <td class="fw-bold text-center" :class="[ch.payinSpecs.enabled ? 'text-success' : 'text-warning']">
            <i v-if="ch.payinSpecs.enabled" class="fa-solid fa-check fa-lg"></i>
            <i v-else class="fa-solid fa-xmark fa-lg"></i>
          </td>
          <td class="fw-bold text-center" :class="[ch.payoutSpecs.enabled ? 'text-success' : 'text-warning']">
            <i v-if="ch.payoutSpecs.enabled" class="fa-solid fa-check fa-lg"></i>
            <i v-else class="fa-solid fa-xmark fa-lg"></i>
          </td>
          <td>{{ch.procId}}</td>
          <td>
            <a class="mx-1" @click.prevent="showForm('COPY', ch.channelCode)" v-b-tooltip.hover title="Copy"><i class="fas fa-copy"></i></a>
            <a class="mx-1" @click.prevent="confirmChannelDel(ch.channelCode)" v-b-tooltip.hover title="Delete"><i class="fas fa-trash text-danger"></i></a>
          </td>
        </tr>
        </template>
      </tbody>
    </table>
  </div>
  
  <b-modal id="ch-form" title="Payment Channel" hide-footer size="lg">
    <payment-channel-form :action="formAction" :channelCode="selectedChannelCode"
      @saved="onFormSaved" @cancelled="$bvModal.hide('ch-form')">
    </payment-channel-form>
  </b-modal>

  <b-modal id="ch-details" title="Payment Channel" hide-footer size="lg">
    <payment-channel-details :action="formAction" :channelCode="selectedChannelCode"
      @saved="onFormSaved" @cancelled="$bvModal.hide('ch-details')">
    </payment-channel-details>
  </b-modal>

  <b-modal id="confirmDelModal" title="Confirm Deletion" @ok="deleteChannel">
    <div class="text-center">
      <span class="fw-bold text-danger">Are you sure you want to delete channel {{selectedChannelCode}} ?</span>
    </div>
  </b-modal>
 
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import PaymentChannelDetails from '../components/PaymentChannelDetails.vue'
import PaymentChannelForm from '../components/PaymentChannelForm.vue'
export default {
  name: 'PaymentChannels',
  mixins: [HelpersMixin],
  components: { PaymentChannelDetails, PaymentChannelForm },
  data () {
    return {
      channels: [],
      formAction: 'EDIT',
      selectedChannelCode: null,
      filterCurrency: 'ALL',
      filterProc: 'ALL',
      currencies: [],
      procIds: []
    }
  },
  mounted () {
    this.getCurrencies()
    this.getChannels()
    this.getProcIdList()
  },
  methods: {
    getChannels: function () {
      this.axios.get('/ax/paymentchannels')
      .then(response => {
        this.channels = response.data
        this.channels.sort((a,b) => {
          if (a.channelCode < b.channelCode) {
            return -1
          }
          if (a.channelCode > b.channelCode) {
            return 1
          }
          return 0
        })
      })
      .catch(error => this.handleError(error))
    },
    showForm: function (action, channelCode) {
      this.formAction = action
      if (action === 'EDIT' || action === 'COPY') {
        this.selectedChannelCode = channelCode
      } else {
        this.selectedChannelCode = null
      }
      this.$bvModal.show('ch-form')
    },
    showDetails: function (channelCode) {
      this.selectedChannelCode = channelCode
      this.$bvModal.show('ch-details')
    },
    onFormSaved: function () {
      this.getChannels()
      this.$bvModal.hide('ch-form')
    },
    confirmChannelDel: function (channelCode) {
      this.selectedChannelCode = channelCode
      this.$bvModal.show('confirmDelModal')
    },
    deleteChannel: function () {
      this.axios.delete('/ax/paymentchannel/' + this.selectedChannelCode)
      .then(() => {
        this.getChannels()
        this.$toasted.success('Channel ' + this.selectedChannelCode + ' deleted')
      })
      .catch(error => this.handleError(error))
    },
    getCurrencies: function () {
      this.axios.get('/ax/refdata/reftype/FIAT_CURRENCIES')
      .then(response => {
        this.currencies = response.data
      })
      .catch(error => this.handleError(error))
    },
    getProcIdList: function () {
        this.axios.get('/ax/bdproclist')
        .then(response => {
          this.procIds = response.data
        })
        .catch(error => this.handleError(error))
    }
  }
}
</script>