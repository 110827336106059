<template>
<div class="container">
  <div v-if="defaultFeeRules && defaultFeeRules.payinEnabled" class="row border border-warning py-1 mb-1">
    <div class="col-12 fst-italic text-warning fw-bold bg-dark my-0 py-1 mb-2">Pay-In Transaction Fee</div>
    <display-field label="Default Transaction Fee" group-class="col-12 text-dark fst-italic">
      <span v-if="defaultFeeRules && defaultFeeRules.payinFeeRule">{{defaultFeeRules.payinFeeRule.feeDescription}}</span>
      <span v-else>Zero Fees</span>
    </display-field>
    <div class="form-check form-switch col-12 ms-3">
      <input v-model="payinFeeOverride" class="form-check-input" type="checkbox" id="payinFeeOverride">
      <label class="form-check-label" for="payinFeeOverride">
        Override Fee? ... <span class="text-danger fst-italic small fw-bold">{{payinFeeOverride ? 'Yes, use override below' : 'No, use default above'}}</span>
      </label>
    </div>
    <div v-if="payinFeeOverride" class="col-auto ms-3 mb-2">
      <div class="row">
      <input-number v-model="payinFeeRule.flatFeeAmount" label="Txn Fee - Flat Amount" :append="defaultFeeRules.currency" :errors="errors" error-key="payinFeeRule.flatFeeAmount" group-class="col-auto"/>
      <input-number v-model="payinFeeRule.pctOfTxnAmount" label="Txn Fee - % of Txn Amount" append="%" :errors="errors" error-key="payinFeeRule.pctOfTxnAmount" group-class="col-auto"/>
      </div>
    </div>
  </div>
  <div v-else class="row border border-warning py-2 mb-1">
    <div class="col-12 fst-italic text-warning fw-bold bg-dark my-0 py-1 mb-2">Pay-In - <span class="text-danger">Disabled</span></div>
  </div>

  <div v-if="defaultFeeRules && defaultFeeRules.payoutEnabled" class="row border border-warning py-1 mb-3">
    <div class="col-12 fst-italic text-warning fw-bold bg-dark my-0 py-1 mb-2">Pay-Out Transaction Fee</div>
    <display-field label="Default Transaction Fee" group-class="col-6 text-dark fst-italic">
      <span v-if="defaultFeeRules && defaultFeeRules.payoutFeeRule">{{defaultFeeRules.payoutFeeRule.feeDescription}}</span>
      <span v-else>Zero Fees</span>
    </display-field>
    <div class="form-check form-switch col-12 ms-3">
      <input v-model="payoutFeeOverride" class="form-check-input" type="checkbox" id="payoutFeeOverride">
      <label class="form-check-label" for="payoutFeeOverride">
        Override Fee? ... <span class="text-danger fst-italic small fw-bold">{{payoutFeeOverride ? 'Yes, use override below' : 'No, use default above'}}</span>
      </label>
    </div>
    <div v-if="payoutFeeOverride" class="col-auto ms-3 mb-2">
      <div class="row">
      <input-number v-model="payoutFeeRule.flatFeeAmount" label="Txn Fee - Flat Amount" :append="defaultFeeRules.currency" :errors="errors" error-key="payoutFeeRule.flatFeeAmount" group-class="col-auto"/>
      <input-number v-model="payoutFeeRule.pctOfTxnAmount" label="Txn Fee - % of Txn Amount" append="%" :errors="errors" error-key="payoutFeeRule.pctOfTxnAmount" group-class="col-auto"/>
      </div>
    </div>
  </div>
  <div v-else class="row border border-warning py-2 mb-1">
    <div class="col-12 fst-italic text-warning fw-bold bg-dark my-0 py-1 mb-2">Pay-Out - <span class="text-danger">Disabled</span></div>
  </div>

  <div class="row">
    <div class="col-auto ms-auto">
      <button class="btn btn-secondary me-2" @click.prevent="onCancel">Cancel</button>
      <button class="btn btn-primary" @click.prevent="onSave">Save</button>
    </div>
  </div>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
    name: 'AccountFeeRuleForm',
    mixins: [HelpersMixin],
    props: ['merchantId', 'accountId', 'currentFeeRules'],
    data () {
        return {
            defaultFeeRules: {},
            payinFeeRule: {flatFeeAmount: 0, pctOfTxnAmount: 0},
            payoutFeeRule: {flatFeeAmount: 0, pctOfTxnAmount: 0},
            payinFeeOverride: false,
            payoutFeeOverride: false
        }
    },
    mounted () {
        this.getDefaultFeeRules()
        if (this.currentFeeRules.payinSourceLevel === 2) {
            this.payinFeeOverride = true
            this.payinFeeRule = this.currentFeeRules.payinFeeRule
        }
        if (this.currentFeeRules.payoutSourceLevel === 2) {
            this.payoutFeeOverride = true
            this.payoutFeeRule = this.currentFeeRules.payoutFeeRule
        }
    },
    methods: {
        getDefaultFeeRules: function () {
            this.axios.get('/ax/defaultfeerules/' + this.currentFeeRules.channelCode + '/' + this.merchantId)
            .then(response => {
                this.defaultFeeRules = response.data
            })
            .catch(error => this.handleError(error))
        },
        onSave: function () {
            var feeRules = {channelCode: this.currentFeeRules.channelCode}
            if (this.payinFeeOverride) {
                feeRules.payinFeeRule = this.payinFeeRule
                feeRules.payinSourceLevel = 2
            } else {
                feeRules.payinFeeRule = this.defaultFeeRules.payinFeeRule
                feeRules.payinSourceLevel = this.defaultFeeRules.payinSourceLevel
            }
            if (this.payoutFeeOverride) {
                feeRules.payoutFeeRule = this.payoutFeeRule
                feeRules.payoutSourceLevel = 2
            } else {
                feeRules.payoutFeeRule = this.defaultFeeRules.payoutFeeRule
                feeRules.payoutSourceLevel = this.defaultFeeRules.payoutSourceLevel
            }
            this.axios.post('/ax/accountfeerules/' + this.merchantId + '/' + this.accountId, feeRules)
            .then( () => {
                this.$emit('saved')
            })
            .catch(error => this.handleError(error))
        },
        onCancel: function () {
            this.$emit('cancelled')
        }
    }
}
</script>