<template>
<div class="container">
  <page-title title="Administrative Ledger Entries"/>
  
  <div class="row text-end">
    <div class="col-auto ms-auto small text-info"><a @click.prevent="showEntryForm" href="#" class="text-decoration-none"><i class="fa-solid fa-square-plus me-1 fa-lg"></i>New Entry</a></div>
  </div>
  
  <div class="row">
    <div class="col-auto">
      <period-selector @periodUpdated="onPeriodUpdated" default-period="LAST7DAYS" />
    </div>
    <div class="col-auto pt-1 ps-5 fs-6">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="dateField" value="createDate"/>
        <label class="form-check-label">Create Date</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="dateField" value="postingDate"/>
        <label class="form-check-label">Posting Date</label>
      </div>
    </div>
  </div>
  
  <div class="row small bg-light mx-1 my-2">
    <div class="col-auto">
      <div class=row><div class="col-auto mb-0 mt-1 smaller">Filters:</div></div>
      <div class="row">
        <input-select v-model="currencyFilter" group-class="col-auto my-1" input-class="form-control-sm pe-5">
          <option value="ALL">Any Currency</option>
          <option v-for="cur in currencies" :key="cur.refCode" :value="cur.refCode">{{cur.refCode}} - {{cur.refValue}}</option>
        </input-select>
        <div class="col-auto my-1">
          <merchant-select @merchantSelected="onMerchantSelected" input-class="form-control-sm pe-5"/>
        </div>
        <input-select v-model="amountGroupFilter" group-class="col-auto my-1" input-class="form-control-sm">
          <option value="ALL">Any Entry Type</option>
          <option value="SETTLEMENTS">Settlements</option>
          <option value="FEES">Fees</option>
          <option value="ADJ_DEBITS">Debit Adjustments</option>
          <option value="ADJ_CREDITS">Credit Adjustments</option>
        </input-select>
      </div>
    </div>
  </div>
  
  <paginated-list :result="result" :search-params="searchParams" :search-fxn="search">
    <table class="col-12 table table-striped table-hover small table-responsive-sm">
      <thead class="table-dark">
        <tr>
          <th width="15%">Create Date</th>
          <th width="15%">Posting Date</th>
          <th>Merchant</th>
          <th>Account</th>
          <th width="5%">Entry Type</th>
          <th colspan="2" class="text-center">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!result || !result.list || result.list.length === 0">
          <td colspan="6" class="text-warning text-center py-3">No Entries Found</td>
        </tr>
        <tr v-for="rec in result.list" :key="rec.recordId" @click="showDetails(rec.recordId)" style="cursor: pointer;">
          <td class="text-nowrap small">
            <display-date :value="rec.createDate" timezone="UTC"/><br/>
            <span class="text-info"><display-date :value="rec.createDate"/></span>
          </td>
          <td class="text-nowrap small">
            <display-date :value="rec.postingDate" timezone="UTC"/><br/>
            <span class="text-info"><display-date :value="rec.postingDate"/></span>
          </td>
          <td><merchant-lookup :merchantId="rec.entityId"/></td>
          <td><account-lookup :merchantId="rec.entityId" :accountId="rec.accountId"/></td>
          <td>{{rec.amountGroup}}</td>
          <td class="text-end text-nowrap"><display-amount :value="rec.amount" :currency="rec.currency"/></td>
          <td width="5%" class="fw-bold" :class="{'text-danger': rec.amountType === 'DR'}">{{rec.amountType}}</td>
        </tr>
      </tbody>
    </table>
  </paginated-list>
  
  <wait-modal :loading="isLoading"/>
  
  <b-modal id="entry-details" title="Ledger Entry Details" hide-footer size="lg">
    <ledger-entry-details :recordId="selectedRecordId">
    </ledger-entry-details>
  </b-modal>

  <b-modal id="entry-form" title="Create Ledger Entry" hide-footer size="lg">
    <admin-ledger-entry-form @saved="onFormSaved" @cancelled="$bvModal.hide('entry-form')">
    </admin-ledger-entry-form>
  </b-modal>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import PeriodSelector from '../components/PeriodSelector.vue'
import PaginatedList from '../components/PaginatedList.vue'
import MerchantSelect from '../components/MerchantSelect.vue'
import MerchantLookup from '../components/MerchantLookup.vue'
import AccountLookup from '../components/AccountLookup.vue'
import WaitModal from '../components/WaitModal.vue'
import LedgerEntryDetails from '../components/LedgerEntryDetails.vue'
import AdminLedgerEntryForm from '../components/AdminLedgerEntryForm.vue'
export default {
  name: 'AdminLedgerEntries',
  mixins: [HelpersMixin],
  components: { PeriodSelector, PaginatedList, WaitModal, MerchantSelect, MerchantLookup, AccountLookup, LedgerEntryDetails, AdminLedgerEntryForm },
  data () {
    return {
      initDone: false,
      result: {},
      searchParams: {},
      dateStart: '',
      dateEnd: '',
      currencyFilter: 'ALL',
      merchantIdFilter: 'ALL',
      accountIdFilter: 'ALL',
      amountGroupFilter: 'ALL',
      currencies: [],
      dateField: 'createDate',
      selectedRecordId: ''
    }
  },
  mounted () {
    this.searchParams = { offset: 0, size: 50 }
    this.getCurrencies()

    this.$watch('currencyFilter', this.applyFilter)
    this.$watch('amountGroupFilter', this.applyFilter)
    this.$watch('dateField', this.applyFilter)
    this.$nextTick(function () {
        this.initDone = true
        this.search(0)
    })
  },
  methods: {
    search: function (offset) {
      if (offset) {
        this.searchParams.offset = offset
      } else {
        this.searchParams.offset = 0
      }
      this.searchParams.rangeField = this.dateField
      this.searchParams.rangeValueFrom = this.dateStart
      this.searchParams.rangeValueTo = this.dateEnd
      this.searchParams.sortField = this.dateField
      this.searchParams.criteria = []
      if (this.currencyFilter && this.currencyFilter !== 'ALL') {
        this.searchParams.criteria.push({ fieldName: 'currency', value: this.currencyFilter, matchType: 'TERM' })
      }
      if (this.merchantIdFilter && this.merchantIdFilter !== 'ALL') {
        this.searchParams.criteria.push({ fieldName: 'entityId', value: this.merchantIdFilter, matchType: 'TERM' })
      }
      if (this.accountIdFilter && this.accountIdFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'accountId', value: this.accountIdFilter, matchType: 'TERM' })
      }
      if (this.amountGroupFilter && this.amountGroupFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'amountGroup', value: this.amountGroupFilter, matchType: 'TERM' })
      }
      this.isLoading = true
      this.result = {}
      this.axios.post('/ax/adminledgerentries/search', this.searchParams)
        .then(response => {
          this.isLoading = false
          this.result = response.data
          if (!this.result.totalRecs || this.result.totalRecs === 0) {
            this.$toasted.show('No entries found for specified criteria')
          }
        })
        .catch(error => this.handleError(error))
    },
    onMerchantSelected: function (eventData) {
      this.merchantIdFilter = eventData.merchantId
      this.accountIdFilter = eventData.accountId
      if (this.initDone) {
          this.search(0)
      }
    },
    onPeriodUpdated: function (eventData) {
      this.dateStart = eventData.startDate
      this.dateEnd = eventData.endDate
      if (this.initDone) {
          this.search(0)
      }
    },
    applyFilter: function () {
      this.search(0)
    },
    showEntryForm: function () {
      this.$bvModal.show('entry-form')
    },
    onFormSaved: function (eventData) {
      this.$bvModal.hide('entry-form')
      this.result.list.splice(0, 0, eventData)
    },
    showDetails: function (recordId) {
      this.selectedRecordId = recordId
      this.$bvModal.show('entry-details')
    },
    getCurrencies: function () {
      this.axios.get('/ax/refdata/reftype/FIAT_CURRENCIES')
      .then(response => {
        this.currencies = response.data
      })
      .catch(error => this.handleError(error))
    }
  }
}
</script>